const questionData = {
  "es": {
    10: {
      title: '¿Cómo cambio mi nombre de usuario?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      
      content: `<p>Para cambiar tu nombre de usuario haz clic en tu nombre de usuario actual, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_es.png')}"></p><p> En la pestaña "General" puedes cambiar tu nombre.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>
      `,
      role: ['teacher'],
    },
    11: {
      title: '¿Puedo cambiar mi email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_es.png')}"></p>
      <p>No, tu email no puede ser modificado. Si no eres usuario de EducaMadrid, para usar otro correo electrónico deberás darte de baja con tu correo actual, y volver a registrarte con el nuevo email.</p>`,
    },
    12: {
      title: '¿Cómo cambio mi contraseña?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>¡Cambiar tu contraseña es muy fácil! solo debes hacer clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_es.png')}"></p>
      <p> En la pestaña "Cambiar contraseña" deberás ingresar tu contraseña anterior, la nueva contraseña, y confirmar la nueva contraseña.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Si eres un usuario de EducaMadrid, no tienes disponible esta opción, y deberás ponerte en contacto con su equipo de soporte para realizar este cambio.</p></div>
      </div>
      `,
    },
    13: {
      title: '¿Cómo cambio el idioma?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Hay dos maneras muy sencillas de cambiar el idioma en que se muestra la información.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_es.png')}">
      <p>En la esquina superior derecha de la pantalla, junto a tu nombre de usuario encontrarás el idioma actual del sitio web. Puedes elegir uno de los idiomas disponibles del menú que se desplegará al pinchar sobre él.</p><p>Automaticamente el idioma de la interfaz cambiará.</p>
      <hr class="help-divider">
      <p>Otra manera es desde los ajustes de tu cuenta. Haz clic en tu nombre de usuario actual, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_es.png')}"></p>
      <p> En la pestaña "General" puedes elegir uno de los idiomas disponibles del menú.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>`,
    },
    14: {
      title: '¿Cómo puedo gestionar mis dispositivos?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Para gestionar tus dispositivos, dirígete a Ajustes de Cuenta haciendo clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_es.png')}"></p>
      <p> En la pestaña "Mis Dispositivos" podrás ver un listado de aquellos dispositivos desde los cuales hayas ingresado a la plataforma.</p><p>Recuerda que puedes tener hasta 5 dispositivos activos. Elimina los que ya no utilices para poder ingresar desde un dispositivo nuevo, haciendo clic en el icono rojo de la papelera.</p>`,
    },
    15: {
      title: '¿Cómo puedo gestionar mis suscripciones?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Dirígete a Ajustes de Cuenta haciendo clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_es.png')}"></p>
      <p>En la pestaña "Mis Suscripciones" podrás ver un listado de todo tu historial.</p><p>Puedes ver más información de cada registro haciendo clic en "Mostrar".</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Si eres un usuario de EducaMadrid, no tienes disponible esta opción.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_es.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_es.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_es.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: '¿Cómo creo un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, haz clic en el botón verde con el símbolo de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_es.png')}""></p>
      <p> Se abrirá un formulario donde deberás indicar el nombre del grupo. Luego haz clic en "Añadir".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_form_es.png')}""></p>
      <p>Tu grupo será añadido al final del listado. Puedes editarlo haciendo clic en su nombre, o seleccionando la opción "Editar" del menú desplegable de la columna "acción".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>`,
    },
    31: {
      title: '¿Cómo puedo ver y editar un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p>En la columna "Acción" tienes la opción de ver los detalles de ese grupo, editarlo o eliminarlo.</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <h4>Detalles</h4><p>En esta vista encontrarás, junto a la información del grupo, un listado con los niños que posee actualmente el grupo. Puedes desvincularlos haciendo clic en el icono rojo con la cadena tachada. También puedes agregar nuevos niños haciendo clic en el link ubicado en la esquina superior de la tarjeta.</p><p>Asimismo, podrás gestionar las cuentas de profesores de la misma manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_es.png')}""></p>
      <h4>Editar Grupo</h4>
      <p>Si entras a la vista de edición, veras una primera pestaña donde puedes cambiar el avatar del grupo y su nombre. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>
      <p>Las dos pestañas siguientes te ayudarán a gestionar tus profesores y niños.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_es.png')}"></p>`,
    },
    32: {
      title: '¿Cómo puedo borrar los grupos que ya no necesito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_es.png')}"></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Eliminar".</p>
      <p>También puedes marcar las casillas ubicadas junto al avatar del grupo, y luego en el botón rojo con la papelera seleccionar la opción "Eliminar Selección".</p>
      <p>Ten en cuenta que eliminar un grupo NO elimina los niños. Éstos serán trasladados a un grupo especial denominado "Niños sin grupo".</p>`,
    },
    33: {
      title: '¿Cómo asigno profesores a un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Profesores Asignados.</p>
      <p>Selecciona los correos electrónicos de todos los profesores que necesites vincular a ese grupo. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    34: {
      title: '¿Cómo añado alumnos a un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_dreamer_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Niños.</p>
      <p>Selecciona los nombres de todos los niños que quieras vincular a ese grupo. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    // 35: {
    //   title: '¿Cómo descargo mi listado actual de grupos?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: '¿Cómo creo un grupo?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: '¿Cómo puedo ver y editar un grupo?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: '¿Cómo puedo borrar los grupos que ya no necesito?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: '¿Cómo asigno profesores a un grupo?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: '¿Cómo añado alumnos a un grupo?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: '¿Cómo descargo mi listado actual de grupos?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },

    //   ],
    //   content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
    //   <p>Pincha en el botón azul "Exportar todos los registros", con el icono del documento de Excel, y automáticamente se descargá el fichero a tu dispositivo.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>`,
    // },
    41: {
      title: '¿Cómo creo un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, haz clic en el botón verde con el símbolo de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_es.png')}""></p>
      <p>Se abrirá un formulario donde deberás indicar el nombre del niño, su fecha de nacimiento, licencia de estudiante (si es que posee una), grupo al que pertenece y número de registro (opcional). Además, tienes la opción de añadir un email familiar, para compartir el niño con un miembro de la familia (que deberá tener una suscripción activa en Smile and Learn). </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_es.png')}""></p>
      <p>Luego haz clic en "Añadir", y tu niño será añadido al final del listado. Puedes editarlo haciendo clic en su nombre, o seleccionando la opción "Editar" del menú desplegable de la columna "acción".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      `,
    },
    42: {
      title: '¿Cómo puedo ver y editar un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p>En la columna "Acción" tienes la opción de ver los detalles de ese niño, editarlo o eliminarlo.</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <h4>Detalles</h4>
      <p>En esta vista encontrarás, junto a la información del niño, un listado con los grupos que posee actualmente. Puedes desvincularlos haciendo clic en el icono rojo con la cadena tachada. También puedes agregar nuevos grupos haciendo clic en el link ubicado en la esquina superior de la tarjeta.</p><p>Asimismo, podrás gestionar las licencias de estudiante de la misma manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_es.png')}""></p>
      <h4>Editar Niño</h4>
      <p>Si entras a la vista de edición, verás una primera pestaña donde puedes cambiar el avatar del niño, su nombre, fecha de nacimiento y número de registro. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>
      <p>Las dos pestañas siguientes te ayudarán a gestionar la licencia de estudiante y grupos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_es.png')}"></p>
      `,
    },
    43: {
      title: '¿Cómo puedo borrar los niños que ya no necesito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <h4>Desactivar un niño</h4>
      <p>En la columna "Acción", pincha en el desplegable del niño a eliminar y selecciona la opción "Eliminar". Al realizar esta acción los niños seleccionados se eliminarán, pudiendo ser recuperados en un plazo de 15 días. Para ello, deberás enviar un correo a support@smileandlearn.com indicando sus nombres.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_es.png')}""></p>
      <h4>Desactivar niños de manera masiva</h4>
      <p>También puedes eliminar varios niños marcando las casillas de verificación ubicadas junto al avatar de cada niño, y luego en el botón rojo con la papelera seleccionar la opción "Desactivar niños seleccionados".</p>
      <p>Al realizar esta acción los niños seleccionados se eliminarán, pudiendo ser recuperados en un plazo de 15 días. Para ello, deberás enviar un correo a support@smileandlearn.com indicando sus nombres.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_es.png')}""></p>
      <h4>Borrar niños de manera masiva con un fichero de Excel</h4>
      <p>Otra manera es dar de baja niños de forma masiva con un fichero de Excel. Esta opción la puedes encontrar en el botón rojo con la papelera, seleccionando la opción "Dar de baja niños con Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_es.png')}""></p>
      <p> Se mostrarán los pasos a seguir, una plantilla de ejemplo descargable, y el panel de carga del documento.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_es.png')}""></p>
      <h4>Eliminar todos los niños</h4>
      <p>Si al subir la estructura de tu centro has cometido algún error, tienes la opción de eliminar todos los niños con un solo botón. El mismo está ubicado en el botón rojo con la papelera, bajo el nombre de "Eliminar todos los niños". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_es.png')}""></p>
      <hr class="help-divider">

      <h3>¿Cuál es la diferencia entre "desactivar", "dar de baja" y "eliminar" un niño?</h3>

      <p><b>Desactivar niños seleccionados</b>: el alumno seguirá vinculado al colegio, pero el docente no lo verá en su grupo. Se puede reactivar en cualquier momento.</p>
      <p>Para ello posees un plazo de 15 días para enviar un correo a support@smileandlearn.com indicando sus nombres y tu cuenta de usuario.</p>
      <p><b>Dar de baja niños con Excel</b>: el alumno se desvinculará por completo del colegio, pero podrá seguir utilizando Smile and Learn con su email y contraseña de EducaMadrid. Sin embargo, su suscripción pasará a ser limitada.</p>
      <p><b>Eliminar todos los registros</b>: se eliminan por completo todos los niños del colegio junto con su licencia y progreso. No tiene vuelta atrás.</p>
      <hr class="help-divider">

      <h3>¿Qué sucede si inicia sesión un niño inactivo?</h3>
      <p>Podrá acceder a Smile and Learn sin restricciones, pero no le aparecerá el perfil vinculado al colegio. Además, puede crearse un nuevo perfil de alumno, algo a evitar si queremos reactivarlo en un futuro cercano.</p>
      <h3>¿Qué sucede si inicia sesión un niño dado de baja?</h3>
      <p>Podrá acceder a Smile and Learn con restricciones (como si no tuviera una licencia de pago activa) y su perfil del colegio estará totalmente borrado.</p>
      <h3>¿Qué sucede si inicia sesión un niño eliminado?</h3>
      <p>No podrá acceder a Smile and Learn.</p>
      `,
    },
    44: {
      title: '¿Cómo asigno grupos a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Grupos.</p>
      <p>Selecciona los nombres de todos los grupos donde quieras que participe ese niño. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    45: {
      title: '¿Cómo asigno un correo electrónico o licencia de estudiante a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Licencia de estudiante.</p>
      <p>Selecciona el correo que corresponda a ese niño. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    // 46: {
    //   title: '¿Cómo descargo mi listado actual de niños?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 41,
    //       question: '¿Cómo creo un niño?',
    //       slug: 'how-do-i-create-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 42,
    //       question: '¿Cómo puedo ver y editar un niño?',
    //       slug: 'how-can-i-see-and-edit-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 43,
    //       question: '¿Cómo puedo borrar los niños que ya no necesito?',
    //       slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 44,
    //       question: '¿Cómo asigno grupos a un niño?',
    //       slug: 'how-do-i-assign-groups-to-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 45,
    //       question: '¿Cómo asigno un correo electronico a un niño?',
    //       slug: 'how-do-i-assign-an-email-to-a-dreamer',
    //       role: ['admin','partner','school-admin','teacher','support'],
    //     },

    //   ],
    //   content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
    //   <p>Pincha en el botón azul "Exportar todos los registros", con el icono del documento de Excel, y automáticamente se descargá el fichero a tu dispositivo.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_export_es.png')}""></p>`,
    // },
    51: {
      title: '¿Cómo elimino la estructura de mi colegio?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: '¿Cómo elimino la estructura de mi colegio?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>En el menú lateral izquierzo, busca la sección de "Estructura de Colegio" y haz clic en "Gestionar".</p>
      <p>A continuación, completa el texto de confirmación y haz clic en eliminar. Recuerda que los niños y su progreso no se eliminarán, solamente se borrarán los grupos del centro, y los niños serán enviados al grupo de "Niños sin grupo".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_es.png')}""></p>`,
    },
  },
  "la": {
    10: {
      title: '¿Cómo cambio mi nombre de usuario?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      
      content: `<p>Para cambiar tu nombre de usuario haz clic en tu nombre de usuario actual, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_es.png')}"></p><p> En la pestaña "General" puedes cambiar tu nombre.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>
      `,
      role: ['teacher'],
    },
    11: {
      title: '¿Puedo cambiar mi email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_es.png')}"></p>
      <p>No, tu email no puede ser modificado. Si no eres usuario de EducaMadrid, para usar otro correo electrónico deberás darte de baja con tu correo actual, y volver a registrarte con el nuevo email.</p>`,
    },
    12: {
      title: '¿Cómo cambio mi contraseña?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>¡Cambiar tu contraseña es muy fácil! solo debes hacer clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_es.png')}"></p>
      <p> En la pestaña "Cambiar contraseña" deberás ingresar tu contraseña anterior, la nueva contraseña, y confirmar la nueva contraseña.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Si eres un usuario de EducaMadrid, no tienes disponible esta opción, y deberás ponerte en contacto con su equipo de soporte para realizar este cambio.</p></div>
      </div>
      `,
    },
    13: {
      title: '¿Cómo cambio el idioma?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Hay dos maneras muy sencillas de cambiar el idioma en que se muestra la información.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_es.png')}">
      <p>En la esquina superior derecha de la pantalla, junto a tu nombre de usuario encontrarás el idioma actual del sitio web. Puedes elegir uno de los idiomas disponibles del menú que se desplegará al pinchar sobre él.</p><p>Automaticamente el idioma de la interfaz cambiará.</p>
      <hr class="help-divider">
      <p>Otra manera es desde los ajustes de tu cuenta. Haz clic en tu nombre de usuario actual, ubicado en la esquina superior derecha de la pantalla. Selecciona la opción "Ajustes" del menú desplegable.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_es.png')}"></p>
      <p> En la pestaña "General" puedes elegir uno de los idiomas disponibles del menú.</p><p>Haz clic en "Guardar cambios" cuando hayas terminado.</p>`,
    },
    14: {
      title: '¿Cómo puedo gestionar mis dispositivos?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Para gestionar tus dispositivos, dirígete a Ajustes de Cuenta haciendo clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_es.png')}"></p>
      <p> En la pestaña "Mis Dispositivos" podrás ver un listado de aquellos dispositivos desde los cuales hayas ingresado a la plataforma.</p><p>Recuerda que puedes tener hasta 5 dispositivos activos. Elimina los que ya no utilices para poder ingresar desde un dispositivo nuevo, haciendo clic en el icono rojo de la papelera.</p>`,
    },
    15: {
      title: '¿Cómo puedo gestionar mis suscripciones?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: '¿Cómo cambio mi nombre de usuario?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: '¿Puedo cambiar mi email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: '¿Cómo cambio mi contraseña?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: '¿Cómo cambio el idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: '¿Cómo puedo gestionar mis dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: '¿Cómo puedo gestionar mis suscripciones?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Dirígete a Ajustes de Cuenta haciendo clic en tu nombre de usuario, ubicado en la esquina superior derecha de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_es.png')}"></p>
      <p>En la pestaña "Mis Suscripciones" podrás ver un listado de todo tu historial.</p><p>Puedes ver más información de cada registro haciendo clic en "Mostrar".</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Si eres un usuario de EducaMadrid, no tienes disponible esta opción.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Recuerda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_es.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_es.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_es.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: '¿Cómo creo un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, haz clic en el botón verde con el símbolo de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_es.png')}""></p>
      <p> Se abrirá un formulario donde deberás indicar el nombre del grupo. Luego haz clic en "Añadir".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_form_es.png')}""></p>
      <p>Tu grupo será añadido al final del listado. Puedes editarlo haciendo clic en su nombre, o seleccionando la opción "Editar" del menú desplegable de la columna "acción".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>`,
    },
    31: {
      title: '¿Cómo puedo ver y editar un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p>En la columna "Acción" tienes la opción de ver los detalles de ese grupo, editarlo o eliminarlo.</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <h4>Detalles</h4><p>En esta vista encontrarás, junto a la información del grupo, un listado con los niños que posee actualmente el grupo. Puedes desvincularlos haciendo clic en el icono rojo con la cadena tachada. También puedes agregar nuevos niños haciendo clic en el link ubicado en la esquina superior de la tarjeta.</p><p>Asimismo, podrás gestionar las cuentas de profesores de la misma manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_es.png')}""></p>
      <h4>Editar Grupo</h4>
      <p>Si entras a la vista de edición, veras una primera pestaña donde puedes cambiar el avatar del grupo y su nombre. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>
      <p>Las dos pestañas siguientes te ayudarán a gestionar tus profesores y niños.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_es.png')}"></p>`,
    },
    32: {
      title: '¿Cómo puedo borrar los grupos que ya no necesito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_es.png')}"></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Eliminar".</p>
      <p>También puedes marcar las casillas ubicadas junto al avatar del grupo, y luego en el botón rojo con la papelera seleccionar la opción "Eliminar Selección".</p>
      <p>Ten en cuenta que eliminar un grupo NO elimina los niños. Éstos serán trasladados a un grupo especial denominado "Niños sin grupo".</p>`,
    },
    33: {
      title: '¿Cómo asigno profesores a un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Profesores Asignados.</p>
      <p>Selecciona los correos electrónicos de todos los profesores que necesites vincular a ese grupo. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    34: {
      title: '¿Cómo añado alumnos a un grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: '¿Cómo creo un grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: '¿Cómo puedo ver y editar un grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: '¿Cómo puedo borrar los grupos que ya no necesito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: '¿Cómo asigno profesores a un grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: '¿Cómo añado alumnos a un grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: '¿Cómo descargo mi listado actual de grupos?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },

      ],
      content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los grupos que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del grupo para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_dreamer_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Niños.</p>
      <p>Selecciona los nombres de todos los niños que quieras vincular a ese grupo. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    // 35: {
    //   title: '¿Cómo descargo mi listado actual de grupos?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: '¿Cómo creo un grupo?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: '¿Cómo puedo ver y editar un grupo?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: '¿Cómo puedo borrar los grupos que ya no necesito?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: '¿Cómo asigno profesores a un grupo?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: '¿Cómo añado alumnos a un grupo?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: '¿Cómo descargo mi listado actual de grupos?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },

    //   ],
    //   content: `<p>Ingresa a la sección de Grupos ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
    //   <p>Pincha en el botón azul "Exportar todos los registros", con el icono del documento de Excel, y automáticamente se descargá el fichero a tu dispositivo.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>`,
    // },
    41: {
      title: '¿Cómo creo un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, haz clic en el botón verde con el símbolo de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_es.png')}""></p>
      <p>Se abrirá un formulario donde deberás indicar el nombre del niño, su fecha de nacimiento, licencia de estudiante (si es que posee una), grupo al que pertenece y número de registro (opcional). Además, tienes la opción de añadir un email familiar, para compartir el niño con un miembro de la familia (que deberá tener una suscripción activa en Smile and Learn). </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_es.png')}""></p>
      <p>Luego haz clic en "Añadir", y tu niño será añadido al final del listado. Puedes editarlo haciendo clic en su nombre, o seleccionando la opción "Editar" del menú desplegable de la columna "acción".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      `,
    },
    42: {
      title: '¿Cómo puedo ver y editar un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p>En la columna "Acción" tienes la opción de ver los detalles de ese niño, editarlo o eliminarlo.</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <h4>Detalles</h4>
      <p>En esta vista encontrarás, junto a la información del niño, un listado con los grupos que posee actualmente. Puedes desvincularlos haciendo clic en el icono rojo con la cadena tachada. También puedes agregar nuevos grupos haciendo clic en el link ubicado en la esquina superior de la tarjeta.</p><p>Asimismo, podrás gestionar las licencias de estudiante de la misma manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_es.png')}""></p>
      <h4>Editar Niño</h4>
      <p>Si entras a la vista de edición, verás una primera pestaña donde puedes cambiar el avatar del niño, su nombre, fecha de nacimiento y número de registro. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>
      <p>Las dos pestañas siguientes te ayudarán a gestionar la licencia de estudiante y grupos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_es.png')}"></p>
      `,
    },
    43: {
      title: '¿Cómo puedo borrar los niños que ya no necesito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <h4>Desactivar un niño</h4>
      <p>En la columna "Acción", pincha en el desplegable del niño a eliminar y selecciona la opción "Eliminar". Al realizar esta acción los niños seleccionados se eliminarán, pudiendo ser recuperados en un plazo de 15 días. Para ello, deberás enviar un correo a support@smileandlearn.com indicando sus nombres.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_es.png')}""></p>
      <h4>Desactivar niños de manera masiva</h4>
      <p>También puedes eliminar varios niños marcando las casillas de verificación ubicadas junto al avatar de cada niño, y luego en el botón rojo con la papelera seleccionar la opción "Desactivar niños seleccionados".</p>
      <p>Al realizar esta acción los niños seleccionados se eliminarán, pudiendo ser recuperados en un plazo de 15 días. Para ello, deberás enviar un correo a support@smileandlearn.com indicando sus nombres.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_es.png')}""></p>
      <h4>Borrar niños de manera masiva con un fichero de Excel</h4>
      <p>Otra manera es dar de baja niños de forma masiva con un fichero de Excel. Esta opción la puedes encontrar en el botón rojo con la papelera, seleccionando la opción "Dar de baja niños con Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_es.png')}""></p>
      <p> Se mostrarán los pasos a seguir, una plantilla de ejemplo descargable, y el panel de carga del documento.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_es.png')}""></p>
      <h4>Eliminar todos los niños</h4>
      <p>Si al subir la estructura de tu centro has cometido algún error, tienes la opción de eliminar todos los niños con un solo botón. El mismo está ubicado en el botón rojo con la papelera, bajo el nombre de "Eliminar todos los niños". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_es.png')}""></p>
      <hr class="help-divider">

      <h3>¿Cuál es la diferencia entre "desactivar", "dar de baja" y "eliminar" un niño?</h3>

      <p><b>Desactivar niños seleccionados</b>: el alumno seguirá vinculado al colegio, pero el docente no lo verá en su grupo. Se puede reactivar en cualquier momento.</p>
      <p>Para ello posees un plazo de 15 días para enviar un correo a support@smileandlearn.com indicando sus nombres y tu cuenta de usuario.</p>
      <p><b>Dar de baja niños con Excel</b>: el alumno se desvinculará por completo del colegio, pero podrá seguir utilizando Smile and Learn con su email y contraseña de EducaMadrid. Sin embargo, su suscripción pasará a ser limitada.</p>
      <p><b>Eliminar todos los registros</b>: se eliminan por completo todos los niños del colegio junto con su licencia y progreso. No tiene vuelta atrás.</p>
      <hr class="help-divider">

      <h3>¿Qué sucede si inicia sesión un niño inactivo?</h3>
      <p>Podrá acceder a Smile and Learn sin restricciones, pero no le aparecerá el perfil vinculado al colegio. Además, puede crearse un nuevo perfil de alumno, algo a evitar si queremos reactivarlo en un futuro cercano.</p>
      <h3>¿Qué sucede si inicia sesión un niño dado de baja?</h3>
      <p>Podrá acceder a Smile and Learn con restricciones (como si no tuviera una licencia de pago activa) y su perfil del colegio estará totalmente borrado.</p>
      <h3>¿Qué sucede si inicia sesión un niño eliminado?</h3>
      <p>No podrá acceder a Smile and Learn.</p>
      `,
    },
    44: {
      title: '¿Cómo asigno grupos a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Grupos.</p>
      <p>Selecciona los nombres de todos los grupos donde quieras que participe ese niño. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    45: {
      title: '¿Cómo asigno un correo electrónico o licencia de estudiante a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: '¿Cómo creo un niño?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: '¿Cómo puedo ver y editar un niño?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: '¿Cómo puedo borrar los niños que ya no necesito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: '¿Cómo asigno grupos a un niño?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: '¿Cómo asigno un correo electronico a un niño?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },

      ],
      content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_es.png')}""></p>
      <p>En la columna "Acción", pincha en el desplegable y selecciona la opción "Editar".</p>
      <p>También puedes hacer clic en el nombre del niño para ir a la vista de detalles, y luego pinchar en el botón "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_es.png')}""></p>
      <p>Una vez en la vista de Edición, selecciona la pestaña de Licencia de estudiante.</p>
      <p>Selecciona el correo que corresponda a ese niño. No olvides hacer clic en "Guardar Cambios" cuando hayas terminado.</p>`,
    },
    // 46: {
    //   title: '¿Cómo descargo mi listado actual de niños?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 41,
    //       question: '¿Cómo creo un niño?',
    //       slug: 'how-do-i-create-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 42,
    //       question: '¿Cómo puedo ver y editar un niño?',
    //       slug: 'how-can-i-see-and-edit-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 43,
    //       question: '¿Cómo puedo borrar los niños que ya no necesito?',
    //       slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 44,
    //       question: '¿Cómo asigno grupos a un niño?',
    //       slug: 'how-do-i-assign-groups-to-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 45,
    //       question: '¿Cómo asigno un correo electronico a un niño?',
    //       slug: 'how-do-i-assign-an-email-to-a-dreamer',
    //       role: ['admin','partner','school-admin','teacher','support'],
    //     },

    //   ],
    //   content: `<p>Ingresa a la sección de Niños ubicada en el menú lateral izquierdo. Una vez dentro, podrás ver un listado de todos los niños que posees actualmente.</p>
    //   <p>Pincha en el botón azul "Exportar todos los registros", con el icono del documento de Excel, y automáticamente se descargá el fichero a tu dispositivo.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_export_es.png')}""></p>`,
    // },
    51: {
      title: '¿Cómo elimino la estructura de mi colegio?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: '¿Cómo elimino la estructura de mi colegio?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>En el menú lateral izquierzo, busca la sección de "Estructura de Colegio" y haz clic en "Gestionar".</p>
      <p>A continuación, completa el texto de confirmación y haz clic en eliminar. Recuerda que los niños y su progreso no se eliminarán, solamente se borrarán los grupos del centro, y los niños serán enviados al grupo de "Niños sin grupo".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_es.png')}""></p>`,
    },
  },
  "en": {
    10: {
      title: 'How do I change my username?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>To change your username, click on your current username located in the top right corner of the screen. Select the "Settings" option from the dropdown menu.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_en.png')}"></p><p> In the "General" tab, you can change your name.</p><p>Click on "Save changes" when you're done.</p>
      `,
    },
    11: {
      title: 'Can I change my email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_en.png')}"></p>
      <p>No, your email cannot be modified. If you are not an EducaMadrid user, to use another email, you will need to unsubscribe with your current email and register again with the new email.</p>`,
    },
    12: {
      title: 'How do I change my password?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>Changing your password is very easy! Just click on your username, located in the top right corner of the screen. Select the "Settings" option from the dropdown menu.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_en.png')}"></p>
      <p> In the "Change Password" tab, you will need to enter your current password, the new password, and confirm the new password.</p><p>Click on "Save changes" when you're done.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>If you are an EducaMadrid user, this option is not available, and you will need to contact their support team to make this change.</p></div>
      </div>
      `,
    },
    13: {
      title: 'How do I change my language?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>There are two very simple ways to change the language in which the information is displayed.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_en.png')}">
      <p>In the top right corner of the screen, next to your username, you will find the current language of the website. You can choose one of the available languages from the menu that will appear when you click on it.</p><p>The interface language will automatically change.</p>
      <hr class="help-divider">
      <p>Another way is from your account settings. Click on your current username, located in the top right corner of the screen. Select the "Settings" option from the dropdown menu.
      </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_en.png')}"></p>
      <p> In the "General" tab, you can choose one of the available languages from the menu.</p><p>Click on "Save changes" when you're done.</p>`,
    },
    14: {
      title: 'How do I handle my devices?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>To manage your devices, go to Account Settings by clicking on your username, located in the top right corner of the screen.<p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_en.png')}"></p>
      <p> In the "My Devices" tab, you will be able to see a list of the devices from which you have accessed the platform.</p><p>Remember that you can have up to 5 active devices. Delete the ones you no longer use to be able to log in from a new device, by clicking on the red trash icon.</p>`,
    },
    15: {
      title: 'How do I handle my suscriptions?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Go to Account Settings by clicking on your username, located in the top right corner of the screen.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_en.png')}"></p>
      <p>In the "My Subscriptions" tab, you will be able to see a list of your entire history.</p><p>You can see more information for each record by clicking on "Show."</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>If you are an EducaMadrid user, this option is not available.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_en.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_en.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_en.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_en.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: 'How do I create a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        // {
        //   id: 35,
        //   question: 'How do I download a list of my groups?',
        //   slug: 'how-do-i-download-a-list-of-my-groups',
        //   role: ['admin'],
        // },
      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, click on the green button with the plus symbol.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_en.png')}""></p>
      <p> A form will open where you must enter the name of the group. Then, click on "Add."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_form_en.png')}""></p>
      <p>Your group will be added to the end of the list. You can edit it by clicking on its name or selecting the "Edit" option from the dropdown menu in the "Action" column.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>`,
    },
    31: {
      title: 'How can I see and edit a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },

      ],
      content: `<p>Go to the Groups section located in the left sidebar menu. Once inside, you will see a list of all the groups you currently own.</p>
      <p>In the "Action" column, you have the option to view the details of that group, edit it, or delete it.</p>
      <p>You can also click on the group's name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}"></p>
      <h4>Details</h4><p>In this view, you will find, along with the group information, a list of the children currently associated with the group. You can unlink them by clicking on the red icon with the crossed chain. You can also add new children by clicking on the link located in the upper corner of the card.</p><p>Likewise, you can manage teacher accounts in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_en.png')}"></p>
      <h4>Edit Group</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the group's avatar and name. Don't forget to click "Save Changes" when you are done.</p>
      <p>The next two tabs will help you manage your teachers and children.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_en.png')}"></p>
      `,
    },
    32: {
      title: 'How can I delete the groups I no longer need?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left sidebar menu. Once inside, you will see a list of all the groups you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_en.png')}"></p>
      <p>In the "Action" column, click on the dropdown and select the "Delete" option.</p>
      <p>You can also check the boxes next to the group's avatar and then, on the red button with the trash can, select the "Delete Selection" option.</p>
      <p>Keep in mind that deleting a group DOES NOT delete the children. They will be transferred to a special group called "Children without group".</p>`,
    },
    33: {
      title: 'How do I assign teachers to a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the group's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_en.png')}""></p>
      <p>Once in the Editing view, select the tab for Assigned Teachers.</p>
      <p>Select the email addresses of all the teachers you need to link to that group. Don't forget to click "Save Changes" when you are done.</p>`,
    },
    34: {
      title: 'How do I assign students to a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the group's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_en.png')}""></p>
      <p>Once in the Editing view, select the tab for Assigned Teachers.</p>
      <p>Select the email addresses of all the teachers you need to link to that group. Don't forget to click "Save Changes" when you are done.</p>`,
    },
    // 35: {
    //   title: 'How do I download a list of my groups?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: 'How do I create a group?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: 'How can I see and edit a group?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: 'How can I delete the groups I no longer need?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: 'How do I assign teachers to a group?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: 'How do I assign students to a group?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: 'How do I download a list of my groups?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },

    //   ],
    //   content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the children you currently have.</p>
    //   <p>Click on the blue "Export all records" button, with the Excel document icon, and the file will automatically download to your device.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_en.png')}""></p>`,
    // },
    36: {
      title: 'How do I view a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left sidebar menu. Once inside, you will see a list of all the groups you currently own.</p>
      <p>In the "Action" column, you have the option to view the details of that group, edit it, or delete it.</p>
      <p>You can also click on the group's name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}"></p>
      <h4>Details</h4><p>In this view, you will find, along with the group information, a list of the children currently associated with the group. You can unlink them by clicking on the red icon with the crossed chain. You can also add new children by clicking on the link located in the upper corner of the card.</p><p>Likewise, you can manage teacher accounts in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_en.png')}"></p>
      <h4>Edit Group</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the group's avatar and name. Don't forget to click "Save Changes" when you are done.</p>
      <p>The next two tabs will help you manage your teachers and children.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_en.png')}"></p>
      `,
    },
    41: {
      title: 'How do I create a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, click on the green button with the plus symbol.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_en.png')}""></p>
      <p>A form will open where you need to enter the child's name, date of birth, student license (if they have one), group they belong to, and registration number (optional). Additionally, you have the option to add a family email to share the child with a family member (who must have an active subscription to Smile and Learn).</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_en.png')}""></p>
      <p>Then click "Add," and your child will be added to the end of the list. You can edit it by clicking on its name or selecting the "Edit" option from the dropdown menu in the "Action" column.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>      
      `,
    },
    42: {
      title: 'How can I see and edit dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently have.</p>
      <p>In the "Action" column, you have the option to view the details of that child, edit it, or delete it.</p>
      <p>You can also click on the child's name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <h4>Details</h4>
      <p>In this view, you will find, along with the child's information, a list of the groups they currently belong to. You can unlink them by clicking on the red icon with the crossed-out chain. You can also add new groups by clicking on the link located in the top corner of the card.</p>
      <p>Similarly, you can manage student licenses in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_en.png')}""></p>
      <h4>Edit Child</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the child's avatar, name, date of birth, and registration number. Don't forget to click "Save Changes" when you are done.</p>
      <p>The next two tabs will help you manage the student license and groups.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_en.png')}"></p>
      `,
    },
    43: {
      title: 'How can I delete the dreamers I no longer need?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently have.</p>
      <h4>Deactivate a Child</h4>
      <p>In the "Action" column, click on the dropdown of the child to be deleted and select the option "Delete." By performing this action, the selected children will be deleted and can be recovered within 15 days. To do this, you must send an email to support@smileandlearn.com indicating their names.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_en.png')}""></p>
      <h4>Deactivate Children in Bulk</h4>
      <p>You can also delete multiple children by checking the checkboxes next to each child's avatar and then clicking the red button with the trash can and selecting the option "Deactivate selected children."</p>
      <p>By performing this action, the selected children will be deleted and can be recovered within 15 days. To do this, you must send an email to support@smileandlearn.com indicating their names.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_en.png')}""></p>
      <h4>Delete Children in Bulk with an Excel File</h4>
      <p>Another way is to deactivate children in bulk with an Excel file. You can find this option in the red button with the trash can, selecting the option "Deactivate children with Excel."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_en.png')}""></p>
      <p>The steps to follow, a downloadable example template, and the document loading panel will be displayed.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_es.png')}""></p>
      <h4>Delete All Children</h4>
      <p>If you have made a mistake when uploading your center's structure, you have the option to delete all children with a single button. It is located in the red button with the trash can, under the name "Delete all children."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_en.png')}""></p>
      <hr class="help-divider">
      
      <h3>What is the difference between "deactivate," "deactivate with Excel," and "delete" a child?</h3>
      
      <p><b>Deactivate selected children</b>: The student will remain linked to the school, but the teacher will not see them in their group. It can be reactivated at any time.</p>
      <p>You have a period of 15 days to send an email to support@smileandlearn.com indicating their names and your user account.</p>
      <p><b>Deactivate children with Excel</b>: The student will be completely unlinked from the school but can still use Smile and Learn with their EducaMadrid email and password. However, their subscription will become limited.</p>
      <p><b>Delete all records</b>: All children in the school, along with their license and progress, are completely deleted. There is no turning back.</p>
      <hr class="help-divider">
      
      <h3>What happens if an inactive child logs in?</h3>
      <p>They can access Smile and Learn without restrictions, but the linked school profile will not appear. Also, they can create a new student profile, something to avoid if we want to reactivate them in the near future.</p>
      <h3>What happens if a deactivated child logs in?</h3>
      <p>They can access Smile and Learn with restrictions (as if they do not have an active paid license), and their school profile will be completely deleted.</p>
      <h3>What happens if a deleted child logs in?</h3>
      <p>They cannot access Smile and Learn.</p>
      `,
    },
    44: {
      title: 'How do I assign groups to a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the child's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_en.png')}""></p>
      <p>Once in the Edit view, select the Groups tab.</p>
      <p>Select the names of all the groups where you want that child to participate. Don't forget to click on "Save Changes" when you're done.</p>
      `,
    },
    45: {
      title: 'How do I assign an email to a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the child's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_en.png')}""></p>
      <p>Once in the Edit view, select the Student License tab.</p>
      <p>Select the email corresponding to that child. Don't forget to click on "Save Changes" when you're done.</p>
      `,
    },
    46: {
      title: 'How do I download a list of my dreamers?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p>Click on the blue button "Export all records," with the Excel document icon, and the file will be automatically downloaded to your device.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_en.png')}""></p>
      `,
    },
    51: {
      title: 'How do I delete school structure?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: 'How do I delete school structure?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>In the left side menu, look for the "School Structure" section and click on "Manage."</p>
      <p>Next, complete the confirmation text and click on delete. Remember that the children and their progress will not be deleted; only the center's groups will be erased, and the children will be moved to the "Children without a group" category.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_en.png')}""></p>`,
    },
  },
  "tr": {
    10: {
      title: 'How do I change my username?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>To change your username, click on your current username located in the top right corner of the screen. Select the "Settings" option from the dropdown menu.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_en.png')}"></p><p> In the "General" tab, you can change your name.</p><p>Click on "Save changes" when you're done.</p>
      `,
    },
    11: {
      title: 'Can I change my email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_en.png')}"></p>
      <p>No, your email cannot be modified. If you are not an EducaMadrid user, to use another email, you will need to unsubscribe with your current email and register again with the new email.</p>`,
    },
    12: {
      title: 'How do I change my password?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>Changing your password is very easy! Just click on your username, located in the top right corner of the screen. Select the "Settings" option from the dropdown menu.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_en.png')}"></p>
      <p> In the "Change Password" tab, you will need to enter your current password, the new password, and confirm the new password.</p><p>Click on "Save changes" when you're done.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>If you are an EducaMadrid user, this option is not available, and you will need to contact their support team to make this change.</p></div>
      </div>
      `,
    },
    13: {
      title: 'How do I change my language?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>There are two very simple ways to change the language in which the information is displayed.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_en.png')}">
      <p>In the top right corner of the screen, next to your username, you will find the current language of the website. You can choose one of the available languages from the menu that will appear when you click on it.</p><p>The interface language will automatically change.</p>
      <hr class="help-divider">
      <p>Another way is from your account settings. Click on your current username, located in the top right corner of the screen. Select the "Settings" option from the dropdown menu.      </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_en.png')}"></p>
      <p> In the "General" tab, you can choose one of the available languages from the menu.</p><p>Click on "Save changes" when you're done.</p>`,
    },
    14: {
      title: 'How do I handle my devices?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>To manage your devices, go to Account Settings by clicking on your username, located in the top right corner of the screen.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_en.png')}"></p>
      <p> In the "My Devices" tab, you will be able to see a list of the devices from which you have accessed the platform.</p><p>Remember that you can have up to 5 active devices. Delete the ones you no longer use to be able to log in from a new device, by clicking on the red trash icon.</p>`,
    },
    15: {
      title: 'How do I handle my suscriptions?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'How do I change my username?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Can I change my email?',
          slug: 'can-i-change-my-email',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 12,
          question: 'How do I change my password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'How do I change my language?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'How can I handle my devices?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'How can I handle my suscriptions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Go to Account Settings by clicking on your username, located in the top right corner of the screen.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_en.png')}"></p>
      <p>In the "My Subscriptions" tab, you will be able to see a list of your entire history.</p><p>You can see more information for each record by clicking on "Show."</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>If you are an EducaMadrid user, this option is not available.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_en.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Remember...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_en.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_en.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_en.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_en.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: 'How do I create a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, click on the green button with the plus symbol.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_en.png')}""></p>
      <p> A form will open where you must enter the name of the group. Then, click on "Add."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_form_en.png')}""></p>
      <p>Your group will be added to the end of the list. You can edit it by clicking on its name or selecting the "Edit" option from the dropdown menu in the "Action" column.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>`,
    },
    31: {
      title: 'How can I see and edit a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left sidebar menu. Once inside, you will see a list of all the groups you currently own.</p>
      <p>In the "Action" column, you have the option to view the details of that group, edit it, or delete it.</p>
      <p>You can also click on the group's name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}"></p>
      <h4>Details</h4><p>In this view, you will find, along with the group information, a list of the children currently associated with the group. You can unlink them by clicking on the red icon with the crossed chain. You can also add new children by clicking on the link located in the upper corner of the card.</p><p>Likewise, you can manage teacher accounts in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_en.png')}"></p>
      <h4>Edit Group</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the group's avatar and name. Don't forget to click "Save Changes" when you are done.</p>
      <p>The next two tabs will help you manage your teachers and children.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_en.png')}"></p>`,
    },
    32: {
      title: 'How can I delete the groups I no longer need?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left sidebar menu. Once inside, you will see a list of all the groups you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_en.png')}"></p>
      <p>In the "Action" column, click on the dropdown and select the "Delete" option.</p>
      <p>You can also check the boxes next to the group's avatar and then, on the red button with the trash can, select the "Delete Selection" option.</p>
      <p>Keep in mind that deleting a group DOES NOT delete the children. They will be transferred to a special group called "Children without group".</p>`,
    },
    33: {
      title: 'How do I assign teachers to a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the group's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_en.png')}""></p>
      <p>Once in the Editing view, select the tab for Assigned Teachers.</p>
      <p>Select the email addresses of all the teachers you need to link to that group. Don't forget to click "Save Changes" when you are done.</p>`,
    },
    34: {
      title: 'How do I assign students to a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the group's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_en.png')}""></p>
      <p>Once in the Editing view, select the tab for Assigned Teachers.</p>
      <p>Select the email addresses of all the teachers you need to link to that group. Don't forget to click "Save Changes" when you are done.</p>`,
    },
    // 35: {
    //   title: 'How do I download a list of my groups?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: 'How do I create a group?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: 'How can I see and edit a group?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: 'How can I delete the groups I no longer need?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: 'How do I assign teachers to a group?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: 'How do I assign students to a group?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: 'How do I download a list of my groups?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },

    //   ],
    //   content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the children you currently have.</p>
    //   <p>Click on the blue "Export all records" button, with the Excel document icon, and the file will automatically download to your device.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_en.png')}""></p>`,
    // },
    36: {
      title: 'How do I view a group?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'How do I create a group?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'How can I see and edit a group?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'How can I delete the groups I no longer need?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'How do I assign teachers to a group?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'How do I assign students to a group?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },


      ],
      content: `<p>Go to the Groups section located in the left sidebar menu. Once inside, you will see a list of all the groups you currently own.</p>
      <p>In the "Action" column, you have the option to view the details of that group, edit it, or delete it.</p>
      <p>You can also click on the group's name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_en.png')}"></p>
      <h4>Details</h4><p>In this view, you will find, along with the group information, a list of the children currently associated with the group. You can unlink them by clicking on the red icon with the crossed chain. You can also add new children by clicking on the link located in the upper corner of the card.</p><p>Likewise, you can manage teacher accounts in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_en.png')}"></p>
      <h4>Edit Group</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the group's avatar and name. Don't forget to click "Save Changes" when you are done.</p>
      <p>The next two tabs will help you manage your teachers and children.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_en.png')}"></p>
      `,
    },
    41: {
      title: 'How do I create a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, click on the green button with the plus symbol.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_en.png')}""></p>
      <p>A form will open where you need to enter the child's name, date of birth, student license (if they have one), group they belong to, and registration number (optional). Additionally, you have the option to add a family email to share the child with a family member (who must have an active subscription to Smile and Learn).</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_en.png')}""></p>
      <p>Then click "Add," and your child will be added to the end of the list. You can edit it by clicking on its name or selecting the "Edit" option from the dropdown menu in the "Action" column.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>`,
    },
    42: {
      title: 'How can I see and edit dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently have.</p>
      <p>In the "Action" column, you have the option to view the details of that child, edit it, or delete it.</p>
      <p>You can also click on the child's name to go to the details view.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <h4>Details</h4>
      <p>In this view, you will find, along with the child's information, a list of the groups they currently belong to. You can unlink them by clicking on the red icon with the crossed-out chain. You can also add new groups by clicking on the link located in the top corner of the card.</p>
      <p>Similarly, you can manage student licenses in the same way.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_en.png')}""></p>
      <h4>Edit Child</h4>
      <p>If you enter the editing view, you will see a first tab where you can change the child's avatar, name, date of birth, and registration number. Don't forget to click "Save Changes" when you are done.</p>
      <p>The next two tabs will help you manage the student license and groups.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_en.png')}"></p>
      `,
    },
    43: {
      title: 'How can I delete the dreamers I no longer need?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently have.</p>
      <h4>Deactivate a Child</h4>
      <p>In the "Action" column, click on the dropdown of the child to be deleted and select the option "Delete." By performing this action, the selected children will be deleted and can be recovered within 15 days. To do this, you must send an email to support@smileandlearn.com indicating their names.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_en.png')}""></p>
      <h4>Deactivate Children in Bulk</h4>
      <p>You can also delete multiple children by checking the checkboxes next to each child's avatar and then clicking the red button with the trash can and selecting the option "Deactivate selected children."</p>
      <p>By performing this action, the selected children will be deleted and can be recovered within 15 days. To do this, you must send an email to support@smileandlearn.com indicating their names.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_en.png')}""></p>
      <h4>Delete Children in Bulk with an Excel File</h4>
      <p>Another way is to deactivate children in bulk with an Excel file. You can find this option in the red button with the trash can, selecting the option "Deactivate children with Excel."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_en.png')}""></p>
      <p>The steps to follow, a downloadable example template, and the document loading panel will be displayed.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_en.png')}""></p>
      <h4>Delete All Children</h4>
      <p>If you have made a mistake when uploading your center's structure, you have the option to delete all children with a single button. It is located in the red button with the trash can, under the name "Delete all children."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_en.png')}""></p>
      <hr class="help-divider">
      
      <h3>What is the difference between "deactivate," "deactivate with Excel," and "delete" a child?</h3>
      
      <p><b>Deactivate selected children</b>: The student will remain linked to the school, but the teacher will not see them in their group. It can be reactivated at any time.</p>
      <p>You have a period of 15 days to send an email to support@smileandlearn.com indicating their names and your user account.</p>
      <p><b>Deactivate children with Excel</b>: The student will be completely unlinked from the school but can still use Smile and Learn with their EducaMadrid email and password. However, their subscription will become limited.</p>
      <p><b>Delete all records</b>: All children in the school, along with their license and progress, are completely deleted. There is no turning back.</p>
      <hr class="help-divider">
      
      <h3>What happens if an inactive child logs in?</h3>
      <p>They can access Smile and Learn without restrictions, but the linked school profile will not appear. Also, they can create a new student profile, something to avoid if we want to reactivate them in the near future.</p>
      <h3>What happens if a deactivated child logs in?</h3>
      <p>They can access Smile and Learn with restrictions (as if they do not have an active paid license), and their school profile will be completely deleted.</p>
      <h3>What happens if a deleted child logs in?</h3>
      <p>They cannot access Smile and Learn.</p>
      `,
    },
    44: {
      title: 'How do I assign groups to a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the child's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_en.png')}""></p>
      <p>Once in the Edit view, select the Groups tab.</p>
      <p>Select the names of all the groups where you want that child to participate. Don't forget to click on "Save Changes" when you're done.</p>
      `,
    },
    45: {
      title: 'How do I assign an email to a dreamer?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Children section located in the left side menu. Once inside, you will be able to see a list of all the children you currently own.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_en.png')}""></p>
      <p>In the "Action" column, click on the dropdown and select the "Edit" option.</p>
      <p>You can also click on the child's name to go to the details view, and then click on the "Edit" button.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_en.png')}""></p>
      <p>Once in the Edit view, select the Student License tab.</p>
      <p>Select the email corresponding to that child. Don't forget to click on "Save Changes" when you're done.</p>
      `,
    },
    46: {
      title: 'How do I download a list of my dreamers?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'How do I create a dreamer?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'How can I see and edit dreamer?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'How can I delete the dreamers I no longer need?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'How do I assign groups to a dreamer?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'How do I assign an email to a dreamer?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'How do I download a list of my dreamers?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Go to the Groups section located in the left side menu. Once inside, you will be able to see a list of all the groups you currently own.</p>
      <p>Click on the blue button "Export all records," with the Excel document icon, and the file will be automatically downloaded to your device.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_en.png')}""></p>
      `,
    },
    51: {
      title: 'How do I delete school structure?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: 'How do I delete school structure?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>In the left side menu, look for the "School Structure" section and click on "Manage."</p>
      <p>Next, complete the confirmation text and click on delete. Remember that the children and their progress will not be deleted; only the center's groups will be erased, and the children will be moved to the "Children without a group" category.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_en.png')}""></p>`,
    },
  },
  "it": {
    10: {
      title: 'Come cambio il mio nome utente?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: 'Come cambio il mio nome utente?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso cambiare la mia email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Come cambio la mia password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Come cambio la lingua?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Come posso gestire i miei dispositivi?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Come posso gestire le mie iscrizioni?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Per cambiare il tuo nome utente, fai clic sul tuo nome utente attuale, situato nell'angolo in alto a destra dello schermo. Seleziona l'opzione 'Impostazioni' dal menu a discesa.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_it.png')}"></p>
      <p> Nella scheda 'Generale' puoi cambiare il tuo nome.</p><p>Fai clic su 'Salvare modifiche' quando hai finito.</p>
      `,
    },
    11: {
      title: 'Posso cambiare la mia email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Come cambio il mio nome utente?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso cambiare la mia email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Come cambio la mia password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Come cambio la lingua?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Come posso gestire i miei dispositivi?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Come posso gestire le mie iscrizioni?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_it.png')}"></p>
      <p>No, la tua email non può essere modificata. Se non sei un utente di EducaMadrid, per utilizzare un altro indirizzo email dovrai cancellarti con l'attuale email e registrarti nuovamente con la nuova email.</p>`,
    },
    12: {
      title: 'Come cambio la mia password?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Come cambio il mio nome utente?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso cambiare la mia email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Come cambio la mia password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Come cambio la lingua?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Come posso gestire i miei dispositivi?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Come posso gestire le mie iscrizioni?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>Cambiare la tua password è molto facile! Devi solo cliccare sul tuo nome utente, situato nell'angolo in alto a destra dello schermo. Seleziona l'opzione "Impostazioni" dal menu a discesa.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_it.png')}"></p>
      <p> Nella scheda "Cambia password", dovrai inserire la tua password attuale, la nuova password e confermare la nuova password.</p><p>Clicca su "Salva modifiche" quando hai finito.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Ricorda...</div>
      <div class="alert-body">
      <p>Se sei un utente di EducaMadrid, questa opzione non è disponibile e dovrai metterti in contatto con il loro team di supporto per effettuare questa modifica.</p></div>
      </div>
      `,
    },
    13: {
      title: 'Come cambio la lingua?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Come cambio il mio nome utente?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso cambiare la mia email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Come cambio la mia password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Come cambio la lingua?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Come posso gestire i miei dispositivi?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Come posso gestire le mie iscrizioni?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Ci sono due modi molto semplici per cambiare la lingua in cui vengono visualizzate le informazioni.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_it.png')}">
      <p>Nell'angolo in alto a destra dello schermo, accanto al tuo nome utente, troverai la lingua attuale del sito web. Puoi scegliere una delle lingue disponibili dal menu che apparirà quando ci cliccherai sopra.</p><p>Automaticamente, la lingua dell'interfaccia cambierà.</p>
      <hr class="help-divider">
      <p>Un altro modo è attraverso le impostazioni del tuo account. Fai clic sul tuo nome utente attuale, situato nell'angolo in alto a destra dello schermo. Seleziona l'opzione "Impostazioni" dal menu a discesa.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_it.png')}"></p>
      <p> Nella scheda "Generale", puoi scegliere una delle lingue disponibili dal menu.</p><p>Clicca su "Salva modifiche" quando hai finito.</p>`,
    },
    14: {
      title: 'Come posso gestire i miei dispositivi?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Come cambio il mio nome utente?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso cambiare la mia email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Come cambio la mia password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Come cambio la lingua?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Come posso gestire i miei dispositivi?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Come posso gestire le mie iscrizioni?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Per gestire i tuoi dispositivi, vai alle Impostazioni dell'Account cliccando sul tuo nome utente, situato nell'angolo in alto a destra dello schermo.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_it.png')}"></p>
      <p> Nella scheda "I Miei Dispositivi", sarai in grado di vedere un elenco dei dispositivi da cui hai accesso alla piattaforma.</p><p>Ricorda che puoi avere fino a 5 dispositivi attivi. Elimina quelli che non usi più per poter accedere da un nuovo dispositivo, cliccando sull'icona rossa del cestino.</p>`,
    },
    15: {
      title: 'Come posso gestire le mie iscrizioni?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Come cambio il mio nome utente?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso cambiare la mia email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Come cambio la mia password?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Come cambio la lingua?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Come posso gestire i miei dispositivi?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Come posso gestire le mie iscrizioni?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Vai alle Impostazioni dell'Account cliccando sul tuo nome utente, situato nell'angolo in alto a destra dello schermo.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_it.png')}"></p>
      <p>Nella scheda "Le Mie Iscrizioni", sarai in grado di vedere un elenco di tutto il tuo storico.</p><p>Puoi vedere ulteriori informazioni per ogni record cliccando su "Mostra."</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Ricorda...</div>
      <div class="alert-body">
      <p>Se sei un utente di EducaMadrid, questa opzione non è disponibile.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Ricorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Ricorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Ricorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Ricorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_es.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_es.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_es.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: 'Come creo un gruppo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Come creo un gruppo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Come posso vedere e modificare un gruppo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Come posso eliminare i gruppi che non mi servono più?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Come assegno insegnanti a un gruppo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Come aggiungo studenti a un gruppo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Come scarico un elenco dei miei gruppi?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedi alla sezione Gruppi situata nel menu laterale sinistro. Una volta dentro, fai clic sul pulsante verde con il simbolo di somma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_it.png')}""></p>
      <p> Si aprirà un modulo dove dovrai indicare il nome del gruppo. Successivamente, fai clic su "Aggiungi."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/group/grupo_crear_form_it.png')}""></p>
      <p>Il tuo gruppo verrà aggiunto alla fine dell'elenco. Puoi modificarlo cliccando sul suo nome o selezionando l'opzione "Modifica" dal menu a discesa nella colonna "Azione".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-25" src="${require('@/assets/images/help/group/grupo_list_edit_it.png')}""></p>`,
    },
    31: {
      title: 'Come posso vedere e modificare un gruppo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Come creo un gruppo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Come posso vedere e modificare un gruppo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Come posso eliminare i gruppi che non mi servono più?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Come assegno insegnanti a un gruppo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Come aggiungo studenti a un gruppo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Come scarico un elenco dei miei gruppi?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedi alla sezione Gruppi situata nel menu laterale sinistro. Una volta all'interno, potrai vedere un elenco di tutti i gruppi di cui sei attualmente proprietario.</p>
      <p>Nella colonna "Azione", hai l'opzione di visualizzare i dettagli di quel gruppo, modificarlo o eliminarlo.</p>
      <p>Puoi anche fare clic sul nome del gruppo per andare alla vista dettagli.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-25" src="${require('@/assets/images/help/group/grupo_list_edit_it.png')}"></p>
      <h4>Dettagli</h4><p>In questa vista, troverai, insieme alle informazioni del gruppo, un elenco dei bambini attualmente associati al gruppo. Puoi scollegarli cliccando sull'icona rossa con la catena barrata. Puoi anche aggiungere nuovi bambini cliccando sul link situato nell'angolo superiore della scheda.</p><p>Allo stesso modo, puoi gestire gli account degli insegnanti nello stesso modo.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_it.png')}"></p>
      <h4>Modifica Gruppo</h4>
      <p>Se entri nella vista di modifica, vedrai una prima scheda dove puoi cambiare l'avatar del gruppo e il suo nome. Non dimenticare di cliccare su "Salva Modifiche" quando hai finito.</p>
      <p>Le due schede successive ti aiuteranno a gestire i tuoi insegnanti e i bambini.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/group/grupo_edit_it.png')}"></p>`,
    },
    32: {
      title: 'Come posso eliminare i gruppi che non mi servono più?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Come creo un gruppo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Come posso vedere e modificare un gruppo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Come posso eliminare i gruppi che non mi servono più?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Come assegno insegnanti a un gruppo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Come aggiungo studenti a un gruppo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Come scarico un elenco dei miei gruppi?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedi alla sezione Gruppi situata nel menu laterale sinistro. Una volta all'interno, potrai vedere un elenco di tutti i gruppi di cui sei attualmente proprietario.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_it.png')}"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del2_it.png')}"></p>
      <p>Nella colonna "Azione", clicca sulla freccia e seleziona l'opzione "Elimina".</p>
      <p>Puoi anche segnare le caselle accanto all'avatar del gruppo e poi, nel pulsante rosso con il cestino, selezionare l'opzione "Elimina Selezione".</p>
      <p>Tieni presente che eliminare un gruppo NON elimina i bambini. Questi saranno trasferiti a un gruppo speciale chiamato "Bambini senza gruppo".</p>
      `,
    },
    33: {
      title: 'Come assegno insegnanti a un gruppo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Come creo un gruppo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Come posso vedere e modificare un gruppo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Come posso eliminare i gruppi che non mi servono più?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Come assegno insegnanti a un gruppo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Come aggiungo studenti a un gruppo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Come scarico un elenco dei miei gruppi?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedi alla sezione Gruppi situata nel menu laterale sinistro. Una volta all'interno, potrai vedere un elenco di tutti i gruppi di cui sei attualmente proprietario.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit2_it.png')}""></p>
      <p>Nella colonna "Azione", clicca sul menù a discesa e seleziona l'opzione "Modifica".</p>
      <p>Puoi anche fare clic sul nome del gruppo per andare alla vista dettagli, e successivamente cliccare sul pulsante "Modifica".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_it.png')}""></p>
      <p>Una volta nella vista di Modifica, seleziona la scheda per i Professori Assegnati.</p>
      <p>Seleziona gli indirizzi email di tutti i professori che devi collegare a quel gruppo. Non dimenticare di cliccare su "Salva Modifiche" quando hai finito.</p>`,
    },
    34: {
      title: 'Come aggiungo studenti a un gruppo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Come creo un gruppo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Come posso vedere e modificare un gruppo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Come posso eliminare i gruppi che non mi servono più?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Come assegno insegnanti a un gruppo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Come aggiungo studenti a un gruppo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Come scarico un elenco dei miei gruppi?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedi alla sezione Gruppi situata nel menu laterale sinistro. Una volta all'interno, potrai vedere un elenco di tutti i gruppi di cui sei attualmente proprietario.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit2_it.png')}""></p>
      <p>Nella colonna "Azione", clicca sul menù a discesa e seleziona l'opzione "Modifica".</p>
      <p>Puoi anche fare clic sul nome del gruppo per andare alla vista dettagli, e successivamente cliccare sul pulsante "Modifica".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_it.png')}""></p>
      <p>Una volta nella vista di Modifica, seleziona la scheda per i Professori Assegnati.</p>
      <p>Seleziona gli indirizzi email di tutti i professori che devi collegare a quel gruppo. Non dimenticare di cliccare su "Salva Modifiche" quando hai finito.</p>
      `,
    },
    // 35: {
    //   title: 'Come scarico un elenco dei miei gruppi?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: 'Come creo un gruppo?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: 'Come posso vedere e modificare un gruppo?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: 'Come posso eliminare i gruppi che non mi servono più?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: 'Come assegno insegnanti a un gruppo?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: 'Come aggiungo studenti a un gruppo?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: 'Come scarico un elenco dei miei gruppi?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },
    //   ],
    //   content: `<p>Accedi alla sezione Gruppi situata nel menu laterale sinistro. Una volta all'interno, potrai vedere un elenco di tutti i bambini che possiedi attualmente.</p>
    //   <p>Clicca sul pulsante blu "Esporta tutti i record", con l'icona del documento Excel, e il file si scaricherà automaticamente sul tuo dispositivo.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>`,
    // },
    41: {
      title: 'Come creo un bambino?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Come creo un bambino?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Come posso vedere e modificare un bambino?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Come posso eliminare i bambini che non mi servono più?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Come assegno gruppi a un bambino?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Come assegno un indirizzo email a un bambino?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Come scarico un elenco dei miei bambini?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Vai alla sezione Bambini situata nel menu laterale sinistro. Una volta all'interno, clicca sul pulsante verde con il simbolo più.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_it.png')}""></p>
      <p>Si aprirà un modulo dove dovrai inserire il nome del bambino, la data di nascita, la licenza studentesca (se ne possiede una), il gruppo di appartenenza e il numero di registrazione (opzionale). Inoltre, hai l'opzione di aggiungere un'email familiare per condividere il bambino con un membro della famiglia (che dovrà avere un'iscrizione attiva a Smile and Learn).</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_it.png')}""></p>
      <p>Poi clicca su "Aggiungi", e il tuo bambino verrà aggiunto alla fine dell'elenco. Puoi modificarlo cliccando sul suo nome o selezionando l'opzione "Modifica" dal menu a discesa della colonna "Azione".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_it.png')}""></p>
      `,
    },
    42: {
      title: '¿Cómo puedo ver y editar un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Come creo un bambino?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Come posso vedere e modificare un bambino?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Come posso eliminare i bambini che non mi servono più?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Come assegno gruppi a un bambino?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Come assegno un indirizzo email a un bambino?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Come scarico un elenco dei miei bambini?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Vai alla sezione Bambini situata nel menu laterale sinistro. Una volta all'interno, potrai vedere un elenco di tutti i bambini che possiedi attualmente.</p>
      <p>Nella colonna "Azione", hai l'opzione di visualizzare i dettagli di quel bambino, modificarlo o eliminarlo.</p>
      <p>Puoi anche fare clic sul nome del bambino per andare alla vista dettagli.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_it.png')}""></p>
      <h4>Dettagli</h4>
      <p>In questa vista troverai, insieme alle informazioni del bambino, un elenco dei gruppi a cui appartiene attualmente. Puoi scollegarli cliccando sull'icona rossa con la catena barrata. Puoi anche aggiungere nuovi gruppi cliccando sul link situato nell'angolo superiore della scheda.</p>
      <p>Allo stesso modo, puoi gestire le licenze degli studenti nello stesso modo.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_it.png')}""></p>
      <h4>Modifica Bambino</h4>
      <p>Se entri nella vista di modifica, vedrai una prima scheda dove puoi cambiare l'avatar del bambino, il nome, la data di nascita e il numero di registrazione. Non dimenticare di cliccare su "Salva Modifiche" quando hai finito.</p>
      <p>Le due schede successive ti aiuteranno a gestire la licenza degli studenti e i gruppi.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_it.png')}"></p>
      `,
    },
    43: {
      title: '¿Cómo puedo borrar los niños que ya no necesito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Come creo un bambino?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Come posso vedere e modificare un bambino?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Come posso eliminare i bambini che non mi servono più?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Come assegno gruppi a un bambino?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Come assegno un indirizzo email a un bambino?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Come scarico un elenco dei miei bambini?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedi alla sezione Bambini situata nel menu laterale sinistro. Una volta all'interno, potrai vedere un elenco di tutti i bambini che possiedi attualmente.</p>
      <h4>Disattivare un Bambino</h4>
      <p>Nella colonna "Azione", clicca sul menu a discesa del bambino da eliminare e seleziona l'opzione "Elimina". Eseguendo questa azione, i bambini selezionati verranno eliminati e potranno essere ripristinati entro 15 giorni. Per farlo, devi inviare un'email a support@smileandlearn.com indicando i loro nomi.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_it.png')}""></p>
      <h4>Disattivare Bambini in Massa</h4>
      <p>Puoi anche eliminare più bambini spuntando le caselle accanto all'avatar di ciascun bambino e poi cliccando sul pulsante rosso con il cestino e selezionando l'opzione "Disattiva i bambini selezionati".</p>
      <p>Eseguendo questa azione, i bambini selezionati verranno eliminati e potranno essere ripristinati entro 15 giorni. Per farlo, devi inviare un'email a support@smileandlearn.com indicando i loro nomi.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_it.png')}""></p>
      <h4>Eliminare Bambini in Massa con un File Excel</h4>
      <p>Un'altra modalità è disattivare i bambini in massa con un file Excel. Puoi trovare questa opzione nel pulsante rosso con il cestino, selezionando l'opzione "Disattiva i bambini con Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_it.png')}""></p>
      <p>Verranno visualizzati i passaggi da seguire, un modello esemplificativo scaricabile e il pannello di caricamento del documento.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_it.png')}""></p>
      <h4>Eliminare Tutti i Bambini</h4>
      <p>Se hai commesso un errore durante il caricamento della struttura del tuo centro, hai la possibilità di eliminare tutti i bambini con un solo pulsante. Si trova nel pulsante rosso con il cestino, sotto il nome "Elimina tutti i bambini".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_it.png')}""></p>
      <hr class="help-divider">
      
      <h3>Qual è la differenza tra "disattivare", "disattivare con Excel" ed "eliminare" un bambino?</h3>
      
      <p><b>Disattiva i bambini selezionati</b>: Lo studente rimarrà collegato alla scuola, ma l'insegnante non lo vedrà nel proprio gruppo. Può essere riattivato in qualsiasi momento.</p>
      <p>Hai un periodo di 15 giorni per inviare un'email a support@smileandlearn.com indicando i loro nomi e il tuo account utente.</p>
      <p><b>Disattiva i bambini con Excel</b>: Lo studente verrà completamente scollegato dalla scuola, ma potrà continuare a utilizzare Smile and Learn con la sua email EducaMadrid e la password. Tuttavia, la sua sottoscrizione diventerà limitata.</p>
      <p><b>Elimina tutti i record</b>: Tutti i bambini della scuola, insieme alla loro licenza e progressi, vengono eliminati completamente. Non c'è modo di tornare indietro.</p>
      <hr class="help-divider">
      
      <h3>Cosa succede se un bambino inattivo effettua l'accesso?</h3>
      <p>Potrà accedere a Smile and Learn senza restrizioni, ma il profilo scolastico collegato non apparirà. Inoltre, potrebbe creare un nuovo profilo studente, da evitare se vogliamo riattivarlo in un futuro prossimo.</p>
      <h3>Cosa succede se un bambino disattivato effettua l'accesso?</h3>
      <p>Potrà accedere a Smile and Learn con restrizioni (come se non avesse una licenza attiva a pagamento), e il suo profilo scolastico verrà completamente cancellato.</p>
      <h3>Cosa succede se un bambino eliminato effettua l'accesso?</h3>
      <p>Non potrà accedere a Smile and Learn.</p>
      `,
    },
    44: {
      title: '¿Cómo asigno grupos a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Come creo un bambino?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Come posso vedere e modificare un bambino?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Come posso eliminare i bambini che non mi servono più?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Come assegno gruppi a un bambino?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Come assegno un indirizzo email a un bambino?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Come scarico un elenco dei miei bambini?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedi alla sezione Bambini situata nel menu laterale sinistro. Una volta dentro, potrai vedere un elenco di tutti i bambini che possiedi attualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_it.png')}""></p>
      <p>Nella colonna "Azione", fai clic sul menu a discesa e seleziona l'opzione "Modifica".</p>
      <p>Puoi anche fare clic sul nome del bambino per andare alla vista dettagli, e poi fare clic sul pulsante "Modifica".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_it.png')}""></p>
      <p>Una volta nella vista di Modifica, seleziona la scheda Gruppi.</p>
      <p>Seleziona i nomi di tutti i gruppi in cui vuoi che partecipi quel bambino. Non dimenticare di fare clic su "Salva Modifiche" quando hai finito.</p>
      `,
    },
    45: {
      title: '¿Cómo asigno un correo electrónico o licencia de estudiante a un niño?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Come creo un bambino?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Come posso vedere e modificare un bambino?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Come posso eliminare i bambini che non mi servono più?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Come assegno gruppi a un bambino?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Come assegno un indirizzo email a un bambino?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Come scarico un elenco dei miei bambini?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedi alla sezione Bambini situata nel menu laterale sinistro. Una volta dentro, potrai vedere un elenco di tutti i bambini che possiedi attualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_it.png')}""></p>
      <p>Nella colonna "Azione", fai clic sul menu a discesa e seleziona l'opzione "Modifica".</p>
      <p>Puoi anche fare clic sul nome del bambino per andare alla vista dettagli, e poi fare clic sul pulsante "Modifica".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_it.png')}""></p>
      <p>Una volta nella vista di Modifica, seleziona la scheda Licenza Studente.</p>
      <p>Seleziona l'email corrispondente a quel bambino. Non dimenticare di fare clic su "Salva Modifiche" quando hai finito.</p>
      `,
    },
    // 46: {
    //   title: '¿Cómo descargo mi listado actual de niños?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 41,
    //       question: 'Come creo un bambino?',
    //       slug: 'how-do-i-create-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 42,
    //       question: 'Come posso vedere e modificare un bambino?',
    //       slug: 'how-can-i-see-and-edit-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 43,
    //       question: 'Come posso eliminare i bambini che non mi servono più?',
    //       slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 44,
    //       question: 'Come assegno gruppi a un bambino?',
    //       slug: 'how-do-i-assign-groups-to-a-dreamer',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 45,
    //       question: 'Come assegno un indirizzo email a un bambino?',
    //       slug: 'how-do-i-assign-an-email-to-a-dreamer',
    //       role: ['admin','partner','school-admin','teacher','support'],
    //     },
    //     {
    //       id: 46,
    //       question: 'Come scarico un elenco dei miei bambini?',
    //       slug: 'how-do-i-download-a-list-of-my-dreamers',
    //       role: ['admin'],
    //     }
    //   ],
    //   content: `<p>Accedi alla sezione Gruppi situata nel menu laterale sinistro. Una volta dentro, potrai vedere un elenco di tutti i bambini che possiedi attualmente.</p>
    //   <p>Fai clic sul pulsante blu "Esporta tutti i record", con l'icona del documento Excel, e il file verrà scaricato automaticamente sul tuo dispositivo.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>
    //   `,
    // },
    51: {
      title: 'Come elimino la struttura della mia scuola?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: 'Come elimino la struttura della mia scuola?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>Nel menu laterale sinistro, cerca la sezione "Struttura della Scuola" e fai clic su "Gestisci."</p>
      <p>Successivamente, completa il testo di conferma e fai clic su elimina. Ricorda che i bambini e il loro progresso non verranno cancellati; verranno solo eliminati i gruppi del centro, e i bambini saranno spostati nel gruppo "Bambini senza gruppo."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_it.png')}""></p>`,
    },
  },
  "fr": {
    10: {
      title: 'Comment puis-je changer mon nom d´utilisateur?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: 'Comment puis-je changer mon nom d´utilisateur?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puis-je changer mon email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Comment puis-je changer mon mot de passe?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Comment puis-je changer la langue?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Comment puis-je gérer mes appareils?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Comment puis-je gérer mes abonnements?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Pour changer votre nom d'utilisateur, cliquez sur votre nom d'utilisateur actuel situé dans le coin supérieur droit de l'écran. Sélectionnez l'option "Paramètres" dans le menu déroulant.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_fr.png')}"></p><p> Dans l'onglet "Général", vous pouvez changer votre nom.</p><p>Cliquez sur "Enregistrer les modifications" lorsque vous avez terminé.</p>
      `,
    },
    11: {
      title: 'Puis-je changer mon email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Comment puis-je changer mon nom d´utilisateur?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puis-je changer mon email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Comment puis-je changer mon mot de passe?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Comment puis-je changer la langue?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Comment puis-je gérer mes appareils?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Comment puis-je gérer mes abonnements?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_fr.png')}"></p>
      <p>Non, votre adresse e-mail ne peut pas être modifiée. Si vous n'êtes pas utilisateur d'EducaMadrid, pour utiliser une autre adresse e-mail, vous devrez vous désinscrire avec votre adresse e-mail actuelle et vous réinscrire avec la nouvelle adresse e-mail.</p>`,
    },
    12: {
      title: 'Comment puis-je changer mon mot de passe?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Comment puis-je changer mon nom d´utilisateur?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puis-je changer mon email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Comment puis-je changer mon mot de passe?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Comment puis-je changer la langue?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Comment puis-je gérer mes appareils?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Comment puis-je gérer mes abonnements?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>Changer votre mot de passe est très facile ! Il vous suffit de cliquer sur votre nom d'utilisateur, situé dans le coin supérieur droit de l'écran. Sélectionnez l'option "Paramètres" dans le menu déroulant.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_fr.png')}"></p>
      <p> Dans l'onglet "Changer de mot de passe", vous devrez entrer votre mot de passe actuel, le nouveau mot de passe et confirmer le nouveau mot de passe.</p><p>Cliquez sur "Enregistrer les modifications" lorsque vous avez terminé.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Souviens-toi...</div>
      <div class="alert-body">
      <p>Si vous êtes un utilisateur d'EducaMadrid, cette option n'est pas disponible, et vous devrez contacter leur équipe de support pour effectuer ce changement.</p></div>
      </div>
      `,
    },
    13: {
      title: 'Comment puis-je changer la langue?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Comment puis-je changer mon nom d´utilisateur?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puis-je changer mon email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Comment puis-je changer mon mot de passe?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Comment puis-je changer la langue?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Comment puis-je gérer mes appareils?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Comment puis-je gérer mes abonnements?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Il y a deux manières très simples de changer la langue dans laquelle l'information est affichée.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_fr.png')}">
      <p>Dans le coin supérieur droit de l'écran, à côté de votre nom d'utilisateur, vous trouverez la langue actuelle du site web. Vous pouvez choisir l'une des langues disponibles dans le menu qui apparaîtra lorsque vous cliquerez dessus.
      </p><p>Automatiquement, la langue de l'interface changera.</p>
      <hr class="help-divider">
      <p>Une autre façon est à partir des paramètres de votre compte. Cliquez sur votre nom d'utilisateur actuel, situé dans le coin supérieur droit de l'écran. Sélectionnez l'option "Paramètres" dans le menu déroulant.p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_fr.png')}"></p>
      <p> Dans l'onglet "Général", vous pouvez choisir l'une des langues disponibles dans le menu.</p><p>Cliquez sur "Enregistrer les modifications" lorsque vous avez terminé.</p>`,
    },
    14: {
      title: 'Comment puis-je gérer mes appareils?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Comment puis-je changer mon nom d´utilisateur?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puis-je changer mon email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Comment puis-je changer mon mot de passe?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Comment puis-je changer la langue?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Comment puis-je gérer mes appareils?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Comment puis-je gérer mes abonnements?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Pour gérer vos appareils, accédez aux Paramètres du Compte en cliquant sur votre nom d'utilisateur, situé dans le coin supérieur droit de l'écran.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_fr.png')}"></p>
      <p> Dans l'onglet "Mes Appareils", vous pourrez voir une liste des appareils à partir desquels vous avez accédé à la plateforme.</p><p>N'oubliez pas que vous pouvez avoir jusqu'à 5 appareils actifs. Supprimez ceux que vous n'utilisez plus afin de pouvoir vous connecter depuis un nouvel appareil, en cliquant sur l'icône rouge de la corbeille.</p>`,
    },
    15: {
      title: 'Comment puis-je gérer mes abonnements?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Comment puis-je changer mon nom d´utilisateur?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puis-je changer mon email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Comment puis-je changer mon mot de passe?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Comment puis-je changer la langue?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Comment puis-je gérer mes appareils?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Comment puis-je gérer mes abonnements?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Accédez aux Paramètres du Compte en cliquant sur votre nom d'utilisateur, situé dans le coin supérieur droit de l'écran.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_fr.png')}"></p>
      <p>Dans l'onglet "Mes Abonnements", vous pourrez voir une liste de tout votre historique.</p><p>Vous pouvez voir plus d'informations pour chaque enregistrement en cliquant sur "Afficher."</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Souviens-toi...</div>
      <div class="alert-body">
      <p>Si vous êtes un utilisateur d'EducaMadrid, cette option n'est pas disponible.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Souviens-toi...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Souviens-toi...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Souviens-toi...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Souviens-toi...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_es.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_es.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_es.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: 'Comment puis-je créer un groupe?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Comment puis-je créer un groupe?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Comment puis-je voir et éditer un groupe?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Comment puis-je supprimer les groupes dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Comment puis-je attribuer des enseignants à un groupe?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Comment puis-je attribuer des élèves à un groupe?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Comment puis-je télécharger la liste de mes groupes actuels?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Accédez à la section des Groupes située dans le menu latéral gauche. Une fois à l'intérieur, cliquez sur le bouton vert avec le symbole plus.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_fr.png')}""></p>
      <p> Un formulaire s'ouvrira où vous devrez indiquer le nom du groupe. Ensuite, cliquez sur "Ajouter."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/group/grupo_crear_form_fr.png')}""></p>
      <p>Votre groupe sera ajouté à la fin de la liste. Vous pouvez le modifier en cliquant sur son nom ou en sélectionnant l'option "Éditer" dans le menu déroulant de la colonne "Action".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_fr.png')}""></p>`,
    },
    31: {
      title: 'Comment puis-je voir et éditer un groupe?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Comment puis-je créer un groupe?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Comment puis-je voir et éditer un groupe?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Comment puis-je supprimer les groupes dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Comment puis-je attribuer des enseignants à un groupe?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Comment puis-je attribuer des élèves à un groupe?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Comment puis-je télécharger la liste de mes groupes actuels?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Rendez-vous à la section Groupes située dans le menu latéral gauche. Une fois à l'intérieur, vous verrez une liste de tous les groupes que vous possédez actuellement.</p>
      <p>Dans la colonne "Action", vous avez la possibilité de voir les détails de ce groupe, de le modifier ou de le supprimer.</p>
      <p>Vous pouvez également cliquer sur le nom du groupe pour accéder à la vue détaillée.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_fr.png')}"></p>
      <h4>Détails</h4><p>Dans cette vue, vous trouverez, avec les informations du groupe, une liste des enfants actuellement associés au groupe. Vous pouvez les dissocier en cliquant sur l'icône rouge avec la chaîne barrée. Vous pouvez également ajouter de nouveaux enfants en cliquant sur le lien situé dans le coin supérieur de la carte.</p><p>De même, vous pouvez gérer les comptes des enseignants de la même manière.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_fr.png')}"></p>
      <h4>Modifier le Groupe</h4>
      <p>Si vous entrez dans la vue de modification, vous verrez un premier onglet où vous pouvez changer l'avatar du groupe et son nom. N'oubliez pas de cliquer sur "Enregistrer les Modifications" lorsque vous avez terminé.</p>
      <p>Les deux onglets suivants vous aideront à gérer vos enseignants et vos enfants.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_fr.png')}"></p>`,
    },
    32: {
      title: 'Comment puis-je supprimer les groupes dont je n´ai plus besoin?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Comment puis-je créer un groupe?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Comment puis-je voir et éditer un groupe?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Comment puis-je supprimer les groupes dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Comment puis-je attribuer des enseignants à un groupe?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Comment puis-je attribuer des élèves à un groupe?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Comment puis-je télécharger la liste de mes groupes actuels?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Rendez-vous à la section Groupes située dans le menu latéral gauche. Une fois à l'intérieur, vous verrez une liste de tous les groupes que vous possédez actuellement.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_fr.png')}"></p>
      <p>Dans la colonne "Action", cliquez sur la flèche déroulante et sélectionnez l'option "Supprimer".</p>
      <p>Vous pouvez également cocher les cases à côté de l'avatar du groupe, puis dans le bouton rouge avec la corbeille, sélectionner l'option "Supprimer la Sélection".</p>
      <p>Remarquez que supprimer un groupe NE supprime PAS les enfants. Ils seront transférés à un groupe spécial appelé "Enfants sans groupe".</p>`,
    },
    33: {
      title: 'Comment puis-je attribuer des enseignants à un groupe?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Comment puis-je créer un groupe?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Comment puis-je voir et éditer un groupe?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Comment puis-je supprimer les groupes dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Comment puis-je attribuer des enseignants à un groupe?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Comment puis-je attribuer des élèves à un groupe?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Comment puis-je télécharger la liste de mes groupes actuels?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Rendez-vous à la section Groupes située dans le menu latéral gauche. Une fois à l'intérieur, vous verrez une liste de tous les groupes que vous possédez actuellement.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_fr.png')}""></p>
      <p>Dans la colonne "Action", cliquez sur la flèche déroulante et sélectionnez l'option "Éditer".</p>
      <p>Vous pouvez également cliquer sur le nom du groupe pour accéder à la vue détaillée, puis cliquer sur le bouton "Éditer".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_fr.png')}""></p>
      <p>Une fois dans la vue d'édition, sélectionnez l'onglet des Enseignants Assignés.</p>
      <p>Sélectionnez les adresses e-mail de tous les enseignants que vous devez lier à ce groupe. N'oubliez pas de cliquer sur "Enregistrer les Modifications" lorsque vous avez terminé.</p>
      `,
    },
    34: {
      title: 'Comment puis-je attribuer des élèves à un groupe?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Comment puis-je créer un groupe?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Comment puis-je voir et éditer un groupe?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Comment puis-je supprimer les groupes dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Comment puis-je attribuer des enseignants à un groupe?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Comment puis-je attribuer des élèves à un groupe?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Comment puis-je télécharger la liste de mes groupes actuels?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Rendez-vous à la section Groupes située dans le menu latéral gauche. Une fois à l'intérieur, vous verrez une liste de tous les groupes que vous possédez actuellement.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_fr.png')}""></p>
      <p>Dans la colonne "Action", cliquez sur la flèche déroulante et sélectionnez l'option "Éditer".</p>
      <p>Vous pouvez également cliquer sur le nom du groupe pour accéder à la vue détaillée, puis cliquer sur le bouton "Éditer".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_fr.png')}""></p>
      <p>Une fois dans la vue d'édition, sélectionnez l'onglet des Enseignants Assignés.</p>
      <p>Sélectionnez les adresses e-mail de tous les enseignants que vous devez lier à ce groupe. N'oubliez pas de cliquer sur "Enregistrer les Modifications" lorsque vous avez terminé.</p>
      `,
    },
    // 35: {
    //   title: 'Comment puis-je télécharger la liste de mes groupes actuels?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: 'Comment puis-je créer un groupe?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: 'Comment puis-je voir et éditer un groupe?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: 'Comment puis-je supprimer les groupes dont je n´ai plus besoin?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: 'Comment puis-je attribuer des enseignants à un groupe?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: 'Comment puis-je attribuer des élèves à un groupe?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: 'Comment puis-je télécharger la liste de mes groupes actuels?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },

    //   ],
    //   content: `<p>Rendez-vous à la section Groupes située dans le menu latéral gauche. Une fois à l'intérieur, vous verrez une liste de tous les enfants que vous possédez actuellement.</p>
    //   <p>Cliquez sur le bouton bleu "Exporter tous les enregistrements", avec l'icône du document Excel, et le fichier se téléchargera automatiquement sur votre appareil.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>`,
    // },
    41: {
      title: 'Comment puis-je créer un rêveur?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Comment puis-je créer un rêveur?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Comment puis-je voir et éditer un rêveur?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Comment puis-je supprimer les rêveurs dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Comment puis-je attribuer des groupes à un rêveur?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Comment puis-je attribuer un e-mail à un rêveur?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Comment puis-je télécharger la liste de mes rêveurs actuels?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Rendez-vous à la section Enfants située dans le menu latéral gauche. Une fois à l'intérieur, cliquez sur le bouton vert avec le symbole plus.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_fr.png')}""></p>
      <p>Un formulaire s'ouvrira où vous devrez indiquer le nom de l'enfant, sa date de naissance, sa licence d'étudiant (s'il en possède une), le groupe auquel il appartient et le numéro d'enregistrement (facultatif). De plus, vous avez la possibilité d'ajouter un e-mail familial pour partager l'enfant avec un membre de la famille (qui doit avoir un abonnement actif à Smile and Learn).</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_fr.png')}""></p>
      <p>Ensuite, cliquez sur "Ajouter", et votre enfant sera ajouté à la fin de la liste. Vous pouvez le modifier en cliquant sur son nom ou en sélectionnant l'option "Éditer" dans le menu déroulant de la colonne "Action".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_fr.png')}""></p>
      `,
    },
    42: {
      title: 'Comment puis-je voir et éditer un rêveur?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Comment puis-je créer un rêveur?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Comment puis-je voir et éditer un rêveur?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Comment puis-je supprimer les rêveurs dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Comment puis-je attribuer des groupes à un rêveur?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Comment puis-je attribuer un e-mail à un rêveur?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Comment puis-je télécharger la liste de mes rêveurs actuels?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Rendez-vous à la section Enfants située dans le menu latéral gauche. Une fois à l'intérieur, vous verrez une liste de tous les enfants que vous possédez actuellement.</p>
      <p>Dans la colonne "Action", vous avez la possibilité de voir les détails de cet enfant, de le modifier ou de le supprimer.</p>
      <p>Vous pouvez également cliquer sur le nom de l'enfant pour accéder à la vue détaillée.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_fr.png')}""></p>
      <h4>Détails</h4>
      <p>Dans cette vue, vous trouverez, avec les informations de l'enfant, une liste des groupes auxquels il appartient actuellement. Vous pouvez les dissocier en cliquant sur l'icône rouge avec la chaîne barrée. Vous pouvez également ajouter de nouveaux groupes en cliquant sur le lien situé dans le coin supérieur de la carte.</p>
      <p>De même, vous pouvez gérer les licences des étudiants de la même manière.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_fr.png')}""></p>
      <h4>Modifier l'Enfant</h4>
      <p>Si vous entrez dans la vue de modification, vous verrez un premier onglet où vous pouvez changer l'avatar de l'enfant, son nom, sa date de naissance et son numéro d'enregistrement. N'oubliez pas de cliquer sur "Enregistrer les Modifications" lorsque vous avez terminé.</p>
      <p>Les deux onglets suivants vous aideront à gérer la licence de l'étudiant et les groupes.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_fr.png')}"></p>
      `,
    },
    43: {
      title: 'Comment puis-je supprimer les rêveurs dont je n´ai plus besoin?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Comment puis-je créer un rêveur?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Comment puis-je voir et éditer un rêveur?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Comment puis-je supprimer les rêveurs dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Comment puis-je attribuer des groupes à un rêveur?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Comment puis-je attribuer un e-mail à un rêveur?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Comment puis-je télécharger la liste de mes rêveurs actuels?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accédez à la section Enfants située dans le menu latéral gauche. Une fois à l'intérieur, vous pourrez voir une liste de tous les enfants que vous avez actuellement.</p>
      <h4>Désactiver un Enfant</h4>
      <p>Dans la colonne "Action", cliquez sur le menu déroulant de l'enfant à supprimer et sélectionnez l'option "Supprimer". En effectuant cette action, les enfants sélectionnés seront supprimés et pourront être récupérés dans un délai de 15 jours. Pour ce faire, vous devez envoyer un e-mail à support@smileandlearn.com en indiquant leurs noms.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_fr.png')}""></p>
      <h4>Désactiver les Enfants en Masse</h4>
      <p>Vous pouvez également supprimer plusieurs enfants en cochant les cases à côté de l'avatar de chaque enfant, puis en cliquant sur le bouton rouge avec la poubelle et en sélectionnant l'option "Désactiver les enfants sélectionnés".</p>
      <p>En effectuant cette action, les enfants sélectionnés seront supprimés et pourront être récupérés dans un délai de 15 jours. Pour ce faire, vous devez envoyer un e-mail à support@smileandlearn.com en indiquant leurs noms.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_fr.png')}""></p>
      <h4>Supprimer les Enfants en Masse avec un Fichier Excel</h4>
      <p>Une autre façon est de désactiver les enfants en masse avec un fichier Excel. Vous pouvez trouver cette option dans le bouton rouge avec la poubelle, en sélectionnant l'option "Désactiver les enfants avec Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_fr.png')}""></p>
      <p>Les étapes à suivre, un modèle exemple téléchargeable et le panneau de chargement du document seront affichés.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_fr.png')}""></p>
      <h4>Supprimer Tous les Enfants</h4>
      <p>Si vous avez commis une erreur lors du téléchargement de la structure de votre centre, vous avez la possibilité de supprimer tous les enfants d'un seul coup. Il se trouve dans le bouton rouge avec la poubelle, sous le nom "Supprimer tous les enfants".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_fr.png')}""></p>
      <hr class="help-divider">
      
      <h3>Quelle est la différence entre "désactiver", "désactiver avec Excel" et "supprimer" un enfant ?</h3>
      
      <p><b>Désactiver les enfants sélectionnés</b> : L'élève restera lié à l'école, mais l'enseignant ne le verra pas dans son groupe. Il peut être réactivé à tout moment.</p>
      <p>Vous disposez d'une période de 15 jours pour envoyer un e-mail à support@smileandlearn.com en indiquant leurs noms et votre compte utilisateur.</p>
      <p><b>Désactiver les enfants avec Excel</b> : L'élève sera complètement déconnecté de l'école, mais pourra toujours utiliser Smile and Learn avec son e-mail EducaMadrid et son mot de passe. Cependant, son abonnement deviendra limité.</p>
      <p><b>Supprimer tous les enregistrements</b> : Tous les enfants de l'école, ainsi que leur licence et leurs progrès, sont complètement supprimés. Il n'y a pas de retour en arrière.</p>
      <hr class="help-divider">
      
      <h3>Que se passe-t-il si un enfant inactif se connecte ?</h3>
      <p>Il pourra accéder à Smile and Learn sans restrictions, mais le profil scolaire lié n'apparaîtra pas. De plus, il peut créer un nouveau profil étudiant, ce qu'il faut éviter si nous voulons le réactiver dans un proche avenir.</p>
      <h3>Que se passe-t-il si un enfant désactivé se connecte ?</h3>
      <p>Il pourra accéder à Smile and Learn avec des restrictions (comme s'il n'avait pas de licence payante active), et son profil scolaire sera complètement supprimé.</p>
      <h3>Que se passe-t-il si un enfant supprimé se connecte ?</h3>
      <p>Il ne pourra pas accéder à Smile and Learn.</p>
      `,
    },
    44: {
      title: 'Comment puis-je attribuer des groupes à un rêveur?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Comment puis-je créer un rêveur?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Comment puis-je voir et éditer un rêveur?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Comment puis-je supprimer les rêveurs dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Comment puis-je attribuer des groupes à un rêveur?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Comment puis-je attribuer un e-mail à un rêveur?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Comment puis-je télécharger la liste de mes rêveurs actuels?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accédez à la section Enfants située dans le menu latéral gauche. Une fois à l'intérieur, vous pourrez voir une liste de tous les enfants que vous possédez actuellement.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_fr.png')}""></p>
      <p>Dans la colonne "Action", cliquez sur la liste déroulante et sélectionnez l'option "Éditer".</p>
      <p>Vous pouvez également cliquer sur le nom de l'enfant pour accéder à la vue détaillée, puis cliquer sur le bouton "Éditer".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_fr.png')}""></p>
      <p>Une fois dans la vue d'édition, sélectionnez l'onglet Groupes.</p>
      <p>Sélectionnez les noms de tous les groupes auxquels vous souhaitez que cet enfant participe. N'oubliez pas de cliquer sur "Enregistrer les modifications" lorsque vous avez terminé.</p>
      `,
    },
    45: {
      title: 'Comment puis-je attribuer un e-mail à un rêveur?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Comment puis-je créer un rêveur?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Comment puis-je voir et éditer un rêveur?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Comment puis-je supprimer les rêveurs dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Comment puis-je attribuer des groupes à un rêveur?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Comment puis-je attribuer un e-mail à un rêveur?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Comment puis-je télécharger la liste de mes rêveurs actuels?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accédez à la section Enfants située dans le menu latéral gauche. Une fois à l'intérieur, vous pourrez voir une liste de tous les enfants que vous possédez actuellement.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_fr.png')}""></p>
      <p>Dans la colonne "Action", cliquez sur la liste déroulante et sélectionnez l'option "Éditer".</p>
      <p>Vous pouvez également cliquer sur le nom de l'enfant pour accéder à la vue détaillée, puis cliquer sur le bouton "Éditer".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_fr.png')}""></p>
      <p>Une fois dans la vue d'édition, sélectionnez l'onglet Licence Étudiante.</p>
      <p>Sélectionnez l'e-mail correspondant à cet enfant. N'oubliez pas de cliquer sur "Enregistrer les modifications" lorsque vous avez terminé.</p>
      `,
    },
    46: {
      title: 'Comment puis-je télécharger la liste de mes rêveurs actuels?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Comment puis-je créer un rêveur?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Comment puis-je voir et éditer un rêveur?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Comment puis-je supprimer les rêveurs dont je n´ai plus besoin?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Comment puis-je attribuer des groupes à un rêveur?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Comment puis-je attribuer un e-mail à un rêveur?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Comment puis-je télécharger la liste de mes rêveurs actuels?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accédez à la section Groupes située dans le menu latéral gauche. Une fois à l'intérieur, vous pourrez voir une liste de tous les enfants que vous possédez actuellement.</p>
      <p>Cliquez sur le bouton bleu "Exporter tous les enregistrements", avec l'icône du document Excel, et le fichier sera automatiquement téléchargé sur votre appareil.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>
      `,
    },
    51: {
      title: 'Comment puis-je supprimer la structure de mon école?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: 'Comment puis-je supprimer la structure de mon école?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>Dans le menu latéral gauche, recherchez la section "Structure Scolaire" et cliquez sur "Gérer."</p>
      <p>Ensuite, complétez le texte de confirmation et cliquez sur supprimer. N'oubliez pas que les enfants et leur progression ne seront pas supprimés ; seuls les groupes du centre seront effacés, et les enfants seront déplacés dans le groupe "Enfants sans groupe".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_fr.png')}""></p>`,
    },
  },
  "pt": {
    10: {
      title: 'Como eu posso mudar meu nome de usuário?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: 'Como eu posso mudar meu nome de usuário?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso mudar meu email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Como eu posso mudar minha senha?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Como eu posso mudar o idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Como eu posso gerenciar meus dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Como eu posso gerenciar minhas assinaturas?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Para alterar seu nome de usuário, clique em seu nome de usuário atual localizado no canto superior direito da tela. Selecione a opção "Configurações" no menu suspenso.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_pt.png')}"></p><p> Na aba "Geral", você pode alterar seu nome.</p><p>Clique em "Salvar alterações" quando terminar.</p>
      `,
    },
    11: {
      title: 'Posso mudar meu email?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Como eu posso mudar meu nome de usuário?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso mudar meu email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Como eu posso mudar minha senha?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Como eu posso mudar o idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Como eu posso gerenciar meus dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Como eu posso gerenciar minhas assinaturas?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_pt.png')}"></p>
      <p>Não, seu e-mail não pode ser modificado. Se você não é um usuário da EducaMadrid, para usar outro e-mail, será necessário cancelar a inscrição com seu e-mail atual e se registrar novamente com o novo e-mail.</p>`,
    },
    12: {
      title: 'Como eu posso mudar minha senha?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Como eu posso mudar meu nome de usuário?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso mudar meu email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Como eu posso mudar minha senha?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Como eu posso mudar o idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Como eu posso gerenciar meus dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Como eu posso gerenciar minhas assinaturas?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>Trocar sua senha é muito fácil! Basta clicar em seu nome de usuário, localizado no canto superior direito da tela. Selecione a opção "Configurações" no menu suspenso.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_pt.png')}"></p>
      <p> Na aba "Alterar senha", você precisará inserir sua senha atual, a nova senha e confirmar a nova senha.</p><p>Clique em "Salvar alterações" quando terminar.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Lembra-te...</div>
      <div class="alert-body">
      <p>Se você é um usuário da EducaMadrid, essa opção não está disponível, e você precisará entrar em contato com a equipe de suporte deles para fazer essa alteração.</p></div>
      </div>
      `,
    },
    13: {
      title: 'Como eu posso mudar o idioma?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Como eu posso mudar meu nome de usuário?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso mudar meu email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Como eu posso mudar minha senha?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Como eu posso mudar o idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Como eu posso gerenciar meus dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Como eu posso gerenciar minhas assinaturas?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Existem duas maneiras muito simples de alterar o idioma em que as informações são exibidas.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_pt.png')}">
      <p>No canto superior direito da tela, ao lado do seu nome de usuário, você encontrará o idioma atual do site. Você pode escolher um dos idiomas disponíveis no menu que aparecerá ao clicar nele.</p><p>Automaticamente, o idioma da interface mudará.</p>
      <hr class="help-divider">
      <p>Outra maneira é através das configurações da sua conta. Clique em seu nome de usuário atual, localizado no canto superior direito da tela. Selecione a opção "Configurações" no menu suspenso.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_pt.png')}"></p>
      <p> Na aba "Geral", você pode escolher um dos idiomas disponíveis no menu.</p><p>Clique em "Salvar alterações" quando terminar.</p>`,
    },
    14: {
      title: 'Como eu posso gerenciar meus dispositivos?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Como eu posso mudar meu nome de usuário?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso mudar meu email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Como eu posso mudar minha senha?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Como eu posso mudar o idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Como eu posso gerenciar meus dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Como eu posso gerenciar minhas assinaturas?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Para gerenciar seus dispositivos, vá para Configurações da Conta clicando em seu nome de usuário, localizado no canto superior direito da tela.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_pt.png')}"></p>
      <p> Na aba "Meus Dispositivos", você poderá ver uma lista dos dispositivos nos quais acessou a plataforma.</p><p>Lembre-se de que você pode ter até 5 dispositivos ativos. Exclua os que você não usa mais para poder fazer login a partir de um novo dispositivo, clicando no ícone vermelho da lixeira.</p>`,
    },
    15: {
      title: 'Como eu posso gerenciar minhas assinaturas?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Como eu posso mudar meu nome de usuário?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Posso mudar meu email?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Como eu posso mudar minha senha?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Como eu posso mudar o idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Como eu posso gerenciar meus dispositivos?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Como eu posso gerenciar minhas assinaturas?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Acesse Configurações da Conta clicando em seu nome de usuário, localizado no canto superior direito da tela.<p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_pt.png')}"></p>
      <p>Na aba "Minhas Assinaturas", você poderá ver uma lista de todo o seu histórico.</p><p>Você pode ver mais informações de cada registro clicando em "Mostrar."</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Lembra-te...</div>
      <div class="alert-body">
      <p>Se você é um usuário da EducaMadrid, essa opção não está disponível.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Lembra-te...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Lembra-te...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Lembra-te...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Lembra-te...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_es.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_es.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_es.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: 'Como eu posso criar um grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Como eu posso criar um grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Como eu posso ver e editar um grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Como eu posso excluir os grupos que não preciso mais?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Como eu posso atribuir professores a um grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Como eu posso atribuir alunos a um grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Como eu posso baixar uma lista dos meus grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Acesse a seção de Grupos localizada no menu lateral esquerdo. Uma vez dentro, clique no botão verde com o símbolo de adição.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_pt.png')}""></p>
      <p> Um formulário será aberto, onde você deve indicar o nome do grupo. Em seguida, clique em "Adicionar."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/group/grupo_crear_form_pt.png')}""></p>
      <p>Seu grupo será adicionado ao final da lista. Você pode editá-lo clicando em seu nome ou selecionando a opção "Editar" no menu suspenso da coluna "Ação".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_pt.png')}""></p>`,
    },
    31: {
      title: 'Como eu posso ver e editar um grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Como eu posso criar um grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Como eu posso ver e editar um grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Como eu posso excluir os grupos que não preciso mais?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Como eu posso atribuir professores a um grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Como eu posso atribuir alunos a um grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Como eu posso baixar uma lista dos meus grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Acesse a seção de Grupos localizada no menu lateral esquerdo. Uma vez dentro, você verá uma lista de todos os grupos que possui atualmente.</p>
      <p>Na coluna "Ação", você tem a opção de ver os detalhes desse grupo, editá-lo ou excluí-lo.</p>
      <p>Você também pode clicar no nome do grupo para ir à visualização de detalhes.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_pt.png')}"></p>
      <h4>Detalhes</h4><p>Nesta visualização, você encontrará, juntamente com as informações do grupo, uma lista com as crianças que possui atualmente no grupo. Você pode desvinculá-las clicando no ícone vermelho com a corrente cortada. Você também pode adicionar novas crianças clicando no link localizado no canto superior do cartão.</p><p>Da mesma forma, você pode gerenciar as contas dos professores.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_pt.png')}"></p>
      <h4>Editar Grupo</h4>
      <p>Se você entrar na visualização de edição, verá uma primeira aba onde pode alterar o avatar do grupo e seu nome. Não se esqueça de clicar em "Salvar Alterações" quando terminar.</p>
      <p>As duas abas seguintes ajudarão você a gerenciar seus professores e crianças.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_pt.png')}"></p>`,
    },
    32: {
      title: 'Como eu posso excluir os grupos que não preciso mais?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Como eu posso criar um grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Como eu posso ver e editar um grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Como eu posso excluir os grupos que não preciso mais?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Como eu posso atribuir professores a um grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Como eu posso atribuir alunos a um grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Como eu posso baixar uma lista dos meus grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Acesse a seção de Grupos localizada no menu lateral esquerdo. Uma vez dentro, você verá uma lista de todos os grupos que possui atualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_pt.png')}"></p>
      <p>Na coluna "Ação", clique no menu suspenso e selecione a opção "Excluir".</p>
      <p>Você também pode marcar as caixas ao lado do avatar do grupo e, em seguida, no botão vermelho com a lixeira, selecionar a opção "Excluir Seleção".</p>
      <p>Tenha em mente que excluir um grupo NÃO exclui as crianças. Elas serão transferidas para um grupo especial chamado "Crianças sem grupo".</p>`,
    },
    33: {
      title: 'Como eu posso atribuir professores a um grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Como eu posso criar um grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Como eu posso ver e editar um grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Como eu posso excluir os grupos que não preciso mais?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Como eu posso atribuir professores a um grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Como eu posso atribuir alunos a um grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Como eu posso baixar uma lista dos meus grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Acesse a seção de Grupos localizada no menu lateral esquerdo. Uma vez dentro, você verá uma lista de todos os grupos que possui atualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_pt.png')}""></p>
      <p>Na coluna "Ação", clique no menu suspenso e selecione a opção "Editar".</p>
      <p>Você também pode clicar no nome do grupo para ir à visualização de detalhes e, em seguida, clicar no botão "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_pt.png')}""></p>
      <p>Uma vez na vista de Edição, selecione a guia de Professores Designados.</p>
      <p>Selecione os endereços de e-mail de todos os professores que precisa vincular a esse grupo. Não se esqueça de clicar em "Salvar Alterações" quando terminar.</p>`,
    },
    34: {
      title: 'Como eu posso atribuir alunos a um grupo?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Como eu posso criar um grupo?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Como eu posso ver e editar um grupo?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Como eu posso excluir os grupos que não preciso mais?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Como eu posso atribuir professores a um grupo?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Como eu posso atribuir alunos a um grupo?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Como eu posso baixar uma lista dos meus grupos?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },

      ],
      content: `<p>Acesse a seção de Grupos localizada no menu lateral esquerdo. Uma vez dentro, você verá uma lista de todos os grupos que possui atualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_pt.png')}""></p>
      <p>Na coluna "Ação", clique no menu suspenso e selecione a opção "Editar".</p>
      <p>Você também pode clicar no nome do grupo para ir à visualização de detalhes e, em seguida, clicar no botão "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_pt.png')}""></p>
      <p>Uma vez na vista de Edição, selecione a guia de Professores Designados.</p>
      <p>Selecione os endereços de e-mail de todos os professores que precisa vincular a esse grupo. Não se esqueça de clicar em "Salvar Alterações" quando terminar.</p>
      `,
    },
    // 35: {
    //   title: 'Como eu posso baixar uma lista dos meus grupos?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: 'Como eu posso criar um grupo?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: 'Como eu posso ver e editar um grupo?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: 'Como eu posso excluir os grupos que não preciso mais?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: 'Como eu posso atribuir professores a um grupo?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: 'Como eu posso atribuir alunos a um grupo?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: 'Como eu posso baixar uma lista dos meus grupos?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },

    //   ],
    //   content: `<p>Acesse a seção de Grupos localizada no menu lateral esquerdo. Uma vez dentro, você verá uma lista de todas as crianças que possui atualmente.</p>
    //   <p>Clique no botão azul "Exportar todos os registros", com o ícone do documento do Excel, e o arquivo será baixado automaticamente para o seu dispositivo.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>`,
    // },
    41: {
      title: 'Como eu posso criar um sonhador?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Como eu posso criar um sonhador?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Como eu posso ver e editar um sonhador?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Como eu posso excluir os sonhadores que não preciso mais?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Como eu posso atribuir grupos a um sonhador?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Como eu posso atribuir um e-mail a um sonhador?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Como eu posso baixar uma lista dos meus sonhadores?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Acesse a seção de Crianças localizada no menu lateral esquerdo. Uma vez dentro, clique no botão verde com o símbolo de adição.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_pt.png')}""></p>
      <p>Um formulário se abrirá, onde você precisará indicar o nome da criança, sua data de nascimento, licença de estudante (se tiver), grupo ao qual pertence e número de registro (opcional). Além disso, você tem a opção de adicionar um e-mail familiar para compartilhar a criança com um membro da família (que deve ter uma assinatura ativa no Smile and Learn).</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_pt.png')}""></p>
      <p>Em seguida, clique em "Adicionar", e sua criança será adicionada ao final da lista. Você pode editá-la clicando em seu nome ou selecionando a opção "Editar" no menu suspenso da coluna "Ação".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_pt.png')}""></p>
      `,
    },
    42: {
      title: 'Como eu posso ver e editar um sonhador?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Como eu posso criar um sonhador?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Como eu posso ver e editar um sonhador?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Como eu posso excluir os sonhadores que não preciso mais?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Como eu posso atribuir grupos a um sonhador?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Como eu posso atribuir um e-mail a um sonhador?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Como eu posso baixar uma lista dos meus sonhadores?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Acesse a seção de Crianças localizada no menu lateral esquerdo. Uma vez dentro, você verá uma lista de todas as crianças que possui atualmente.</p>
      <p>Na coluna "Ação", você tem a opção de ver os detalhes dessa criança, editá-la ou excluí-la.</p>
      <p>Você também pode clicar no nome da criança para ir à visualização de detalhes.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_pt.png')}""></p>
      <h4>Detalhes</h4>
      <p>Nesta vista, você encontrará, juntamente com as informações da criança, uma lista dos grupos aos quais ela pertence atualmente. Você pode desvinculá-los clicando no ícone vermelho com a corrente cortada. Você também pode adicionar novos grupos clicando no link localizado no canto superior do cartão.</p>
      <p>Da mesma forma, você pode gerenciar as licenças de estudante da mesma maneira.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_pt.png')}""></p>
      <h4>Editar Criança</h4>
      <p>Se entrar na vista de edição, verá uma primeira aba onde pode mudar o avatar da criança, o nome, a data de nascimento e o número de registro. Não se esqueça de clicar em "Salvar Alterações" quando terminar.</p>
      <p>As duas abas seguintes o ajudarão a gerenciar a licença do estudante e os grupos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_pt.png')}"></p>
      `,
    },
    43: {
      title: 'Como eu posso excluir os sonhadores que não preciso mais?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Como eu posso criar um sonhador?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Como eu posso ver e editar um sonhador?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Como eu posso excluir os sonhadores que não preciso mais?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Como eu posso atribuir grupos a um sonhador?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Como eu posso atribuir um e-mail a um sonhador?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Como eu posso baixar uma lista dos meus sonhadores?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Acesse a seção de Crianças localizada no menu lateral esquerdo. Uma vez dentro, você poderá ver uma lista de todas as crianças que possui atualmente.</p>
      <h4>Desativar uma Criança</h4>
      <p>Na coluna "Ação", clique no menu suspenso da criança a ser excluída e selecione a opção "Excluir". Ao realizar esta ação, as crianças selecionadas serão excluídas e podem ser recuperadas dentro de 15 dias. Para fazer isso, é necessário enviar um e-mail para support@smileandlearn.com indicando seus nomes.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_pt.png')}""></p>
      <h4>Desativar Crianças em Massa</h4>
      <p>Você também pode excluir várias crianças marcando as caixas ao lado do avatar de cada criança e, em seguida, clicando no botão vermelho com a lixeira e selecionando a opção "Desativar crianças selecionadas".</p>
      <p>Ao realizar esta ação, as crianças selecionadas serão excluídas e podem ser recuperadas dentro de 15 dias. Para fazer isso, é necessário enviar um e-mail para support@smileandlearn.com indicando seus nomes.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_pt.png')}""></p>
      <h4>Excluir Crianças em Massa com um Arquivo Excel</h4>
      <p>Outra opção é desativar crianças em massa com um arquivo Excel. Você pode encontrar esta opção no botão vermelho com a lixeira, selecionando a opção "Desativar crianças com Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_pt.png')}""></p>
      <p>Serão exibidos os passos a seguir, um modelo de exemplo para download e o painel de carregamento do documento.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_pt.png')}""></p>
      <h4>Excluir Todas as Crianças</h4>
      <p>Se você cometeu algum erro ao carregar a estrutura do seu centro, tem a opção de excluir todas as crianças com um único botão. Ele está localizado no botão vermelho com a lixeira, sob o nome "Excluir todas as crianças".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_pt.png')}""></p>
      <hr class="help-divider">
      
      <h3>Qual é a diferença entre "desativar", "desativar com Excel" e "excluir" uma criança?</h3>
      
      <p><b>Desativar crianças selecionadas</b>: O aluno permanecerá vinculado à escola, mas o professor não o verá em seu grupo. Pode ser reativado a qualquer momento.</p>
      <p>Você tem um período de 15 dias para enviar um e-mail para support@smileandlearn.com indicando seus nomes e sua conta de usuário.</p>
      <p><b>Desativar crianças com Excel</b>: O aluno será completamente desvinculado da escola, mas ainda poderá usar o Smile and Learn com seu e-mail EducaMadrid e senha. No entanto, a assinatura dele se tornará limitada.</p>
      <p><b>Excluir todos os registros</b>: Todas as crianças da escola, junto com sua licença e progresso, são excluídas completamente. Não há como voltar atrás.</p>
      <hr class="help-divider">
      
      <h3>O que acontece se uma criança inativa fizer login?</h3>
      <p>Ela pode acessar o Smile and Learn sem restrições, mas o perfil escolar vinculado não aparecerá. Além disso, ela pode criar um novo perfil de aluno, algo a ser evitado se quisermos reativá-la em um futuro próximo.</p>
      <h3>O que acontece se uma criança desativada fizer login?</h3>
      <p>Ela pode acessar o Smile and Learn com restrições (como se não tivesse uma licença paga ativa), e seu perfil escolar será completamente excluído.</p>
      <h3>O que acontece se uma criança excluída fizer login?</h3>
      <p>Ela não poderá acessar o Smile and Learn.</p>
      `,
    },
    44: {
      title: 'Como eu posso atribuir grupos a um sonhador?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Como eu posso criar um sonhador?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Como eu posso ver e editar um sonhador?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Como eu posso excluir os sonhadores que não preciso mais?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Como eu posso atribuir grupos a um sonhador?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Como eu posso atribuir um e-mail a um sonhador?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Como eu posso baixar uma lista dos meus sonhadores?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Acesse a seção de Crianças localizada no menu lateral esquerdo. Uma vez dentro, você poderá ver um listado de todas as crianças que possui atualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_pt.png')}""></p>
      <p>Na coluna "Ação", clique no menu suspenso e selecione a opção "Editar".</p>
      <p>Você também pode clicar no nome da criança para ir à vista de detalhes e, em seguida, clicar no botão "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_pt.png')}""></p>
      <p>Uma vez na vista de Edição, selecione a aba Grupos.</p>
      <p>Selecione os nomes de todos os grupos nos quais deseja que essa criança participe. Não se esqueça de clicar em "Salvar Alterações" quando terminar.</p>
      `,
    },
    45: {
      title: 'Como eu posso atribuir um e-mail a um sonhador?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Como eu posso criar um sonhador?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Como eu posso ver e editar um sonhador?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Como eu posso excluir os sonhadores que não preciso mais?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Como eu posso atribuir grupos a um sonhador?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Como eu posso atribuir um e-mail a um sonhador?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Como eu posso baixar uma lista dos meus sonhadores?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Acesse a seção de Crianças localizada no menu lateral esquerdo. Uma vez dentro, você poderá ver um listado de todas as crianças que possui atualmente.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_pt.png')}""></p>
      <p>Na coluna "Ação", clique no menu suspenso e selecione a opção "Editar".</p>
      <p>Você também pode clicar no nome da criança para ir à vista de detalhes e, em seguida, clicar no botão "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_pt.png')}""></p>
      <p>Uma vez na vista de Edição, selecione a aba Licença de Estudante.</p>
      <p>Selecione o e-mail correspondente a essa criança. Não se esqueça de clicar em
      `,
    },
    46: {
      title: 'Como eu posso baixar uma lista dos meus sonhadores?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Como eu posso criar um sonhador?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Como eu posso ver e editar um sonhador?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Como eu posso excluir os sonhadores que não preciso mais?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Como eu posso atribuir grupos a um sonhador?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Como eu posso atribuir um e-mail a um sonhador?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Como eu posso baixar uma lista dos meus sonhadores?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Acesse a seção de Grupos localizada no menu lateral esquerdo. Uma vez dentro, você poderá ver um listado de todas as crianças que possui atualmente.</p>
      <p>Clique no botão azul "Exportar todos os registros", com o ícone do documento do Excel, e o arquivo será baixado automaticamente para o seu dispositivo.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>
      `,
    },
    51: {
      title: 'Como eu posso excluir a estrutura da minha escola?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: 'Como eu posso excluir a estrutura da minha escola?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>No menu lateral esquerdo, procure pela seção "Estrutura Escolar" e clique em "Gerenciar."</p>
      <p>A seguir, complete o texto de confirmação e clique em excluir. Lembre-se de que as crianças e seu progresso não serão excluídos; apenas os grupos do centro serão apagados, e as crianças serão movidas para o grupo de "Crianças sem grupo."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_pt.png')}""></p>`,
    },
  },
  "ca": {
    10: {
      title: 'Com canvio el meu nom d´usuari?',
      lastUpdated: '10 Sep 2022',
      role: ['admin', 'user','partner','school-admin','teacher','support'],
      relatedQuestions: [
        {
          id: 10,
          question: 'Com canvio el meu nom d´usuari?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puc canviar el meu correu electrònic?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Com canvio la meva contrasenya?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Com canvio l´idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Com puc gestionar els meus dispositius?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Com puc gestionar les meves subscripcions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Per canviar el teu nom d'usuari, fes clic al teu nom d'usuari actual situat a la cantonada superior dreta de la pantalla. Selecciona l'opció "Configuració" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_ca.png')}"></p><p> A la pestanya "General" pots canviar el teu nom.</p><p>Fes clic a "Desa els canvis" quan hagis acabat.</p>
      `,
    },
    11: {
      title: 'Puc canviar el meu correu electrònic?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Com canvio el meu nom d´usuari?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puc canviar el meu correu electrònic?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Com canvio la meva contrasenya?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Com canvio l´idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Com puc gestionar els meus dispositius?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Com puc gestionar les meves subscripcions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_general_ca.png')}"></p>
      <p>No, el teu correu electrònic no es pot modificar. Si no ets usuari d'EducaMadrid, per utilitzar una altra adreça de correu electrònic hauràs de donar-te de baixa amb el teu correu actual i tornar-te a registrar amb el nou correu electrònic.</p>`,
    },
    12: {
      title: 'Com canvio la meva contrasenya?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Com canvio el meu nom d´usuari?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puc canviar el meu correu electrònic?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Com canvio la meva contrasenya?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Com canvio l´idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Com puc gestionar els meus dispositius?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Com puc gestionar les meves subscripcions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `
      <p>Canviar la teva contrasenya és molt fàcil! Només has de fer clic al teu nom d'usuari, situat a la cantonada superior dreta de la pantalla. Selecciona l'opció "Configuració" del menú desplegable.</p><p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_pass_ca.png')}"></p>
      <p> A la pestanya "Canviar contrasenya" hauràs d'introduir la teva contrasenya actual, la nova contrasenya i confirmar la nova contrasenya.</p><p>Fes clic a "Desa els canvis" quan hagis acabat.</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recorda...</div>
      <div class="alert-body">
      <p>Si ets un usuari d'EducaMadrid, aquesta opció no està disponible, i hauràs de posar-te en contacte amb el seu equip de suport per realitzar aquest canvi.</p></div>
      </div>
      `,
    },
    13: {
      title: 'Com canvio l´idioma?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Com canvio el meu nom d´usuari?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puc canviar el meu correu electrònic?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Com canvio la meva contrasenya?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Com canvio l´idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Com puc gestionar els meus dispositius?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Com puc gestionar les meves subscripcions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Hi ha dues maneres molt senzilles de canviar l'idioma en què es mostra la informació.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang2_ca.png')}">
      <p>A la cantonada superior dreta de la pantalla, al costat del teu nom d'usuari, trobaràs l'idioma actual del lloc web. Pots escollir un dels idiomes disponibles en el menú que es desplegarà en fer clic sobre ell.</p><p>Automàticament, l'idioma de la interfície canviarà.</p>
      <hr class="help-divider">
      <p>Una altra manera és des dels ajustos del teu compte. Fes clic al teu nom d'usuari actual, situat a la cantonada superior dreta de la pantalla. Selecciona l'opció "Configuració" del menú desplegable.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_lang_ca.png')}"></p>
      <p> A la pestanya "General" pots escollir un dels idiomes disponibles del menú.</p><p>Fes clic a "Desa els canvis" quan hagis acabat.</p>`,
    },
    14: {
      title: 'Com puc gestionar els meus dispositius?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Com canvio el meu nom d´usuari?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puc canviar el meu correu electrònic?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Com canvio la meva contrasenya?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Com canvio l´idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Com puc gestionar els meus dispositius?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Com puc gestionar les meves subscripcions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Per gestionar els teus dispositius, ves a Configuració de Compte fent clic al teu nom d'usuari, situat a la cantonada superior dreta de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_devices_ca.png')}"></p>
      <p> A la pestanya "Els Meus Dispositius" podràs veure un llistat dels dispositius des dels quals hagis accedit a la plataforma.</p><p>Recorda que pots tenir fins a 5 dispositius actius. Elimina aquells que ja no utilitzis per poder ingressar des d'un dispositiu nou, fent clic a l'ícona vermella de la paperera.</p>`,
    },
    15: {
      title: 'Com puc gestionar les meves subscripcions?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 10,
          question: 'Com canvio el meu nom d´usuari?',        
          slug: 'how-do-i-change-my-username',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 11,
          question: 'Puc canviar el meu correu electrònic?',
          slug: 'can-i-change-my-email',
          role: ['admin'],
        },
        {
          id: 12,
          question: 'Com canvio la meva contrasenya?',
          slug: 'how-do-i-change-my-password',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 13,
          question: 'Com canvio l´idioma?',
          slug: 'how-do-i-change-my-language',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 14,
          question: 'Com puc gestionar els meus dispositius?',
          slug: 'how-do-i-handle-my-devices',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
        {
          id: 15,
          question: 'Com puc gestionar les meves subscripcions?',
          slug: 'how-do-i-handle-my-subscriptions',
          role: ['admin', 'user','school-admin','teacher','support'],
        },
      ],
      content: `<p>Ves a Configuració de Compte fent clic al teu nom d'usuari, situat a la cantonada superior dreta de la pantalla.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/ajustes/ajustes_subs_ca.png')}"></p>
      <p>A la pestanya "Les Meves Subscripcions" podràs veure un llistat de tot el teu historial.</p><p>Pots veure més informació de cada registre fent clic a "Mostrar."</p>
      <div class="alert alert-warning">
      <div class="alert-heading">Recorda...</div>
      <div class="alert-body">
      <p>Si ets un usuari d'EducaMadrid, aquesta opció no està disponible.</p>
      </div>
      </div>`,
    },
/*     20: {
      title: '¿Cómo funciona la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a la opción de crear la estructura de tu colegio con la integración de Raíces solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>En Smile and Learn hemos desarrollado un nuevo y mejorado proceso para crear la estructura inicial de tu colegio. En tan solo unos pocos pasos podrás tener toda la información de tu centro preparada para que tu alumnado pueda empezar a trabajar sin demoras.</p><p>Para llevar a cabo el procedimiento solo necesitas tener a mano los ficheros que proporcionan Raíces y EducaMadrid, y subirlos a la plataforma. Nosotros nos encargaremos de todo lo demás.</p><p>No te preocupes ya que antes de realizar cualquier cambio, te pediremos que valides la información, por lo que no habrá riesgos de perder ningún dato importante.</p>`,
    }, */
    21: {
      title: '¿Qué necesito antes de usar la integración con Raíces?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <div class="alert alert-warning">
      <div class="alert-heading">Recorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad Autónoma de Madrid.</p>
      </div>
      </div>
      <p>Antes de comenzar asegúrate de obtener los ficheros de datos de Raíces y EducaMadrid tal como te explicaremos a continuación.</p>
      <p>Lo primero que debes hacer es ingresar a las plataformas oficiales para obtener los listados del alumnado:</p>
      <p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>, siguiendo las instrucciones:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
          <li>Pincha en "Gestión de Usuarios"</li>
          <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
          <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>

      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
          <li>Dirígete a Alumnado</li>
          <li>Pincha en Alumnado del centro</li>
          <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
          <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
          <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>

      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>

      <p>Una vez tengas estos dos archivos, estarás listo para generar tu estructura de forma rápida y sencilla.</p>
      <p>Recuerda que, ante cualquier duda, puedes escribirnos a support@smileandlearn.com </p>


      `,
    },
    22: {
      title: '¿Qué significan los estados y acciones en la tabla de validación de información?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<p>En la tabla de validación de la información, encontrarás las columnas "Estado" y "Acción". </p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>La primera columna indica si el registro cuenta con la información completa para poder ser guardado. En el caso de que el estado aparezca en color rojo con un "KO" deberás volver atrás, completar la información en el fichero correspondiente y volver a subirlo.</p>
      <p>La segunda columna, indica la acción que llevará a cabo el sistema una vez hayas confirmado que la información es correcta. Puedes recibir dos mensajes:</p>
      <ul>
      <li><b>CREAR:</b> El sistema detecta que es un nuevo registro, y por lo tanto lo creará.</li>
      <li><b>ACTUALIZAR:</b> El sistema ha encontrado un registro con el mismo identificador en la base de datos, por lo que sobreescribirá el registro con la nueva información proporcionada.</li>
      </ul>
      <div class="alert alert-warning">
      <div class="alert-heading">Recorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      `,
    },
    24: {
      title: 'Integración con Raíces: Manual de Uso',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `<div class="alert alert-warning">
      <div class="alert-heading">Recorda...</div>
      <div class="alert-body">
      <p>Tendrás acceso a esta opción solo si eres coordinador o coordinadora TIC o si tienes el rol de administración de un colegio de la Comunidad de Madrid.</p>
      </div>
      </div>
      <p>En este manual vamos a explicarte paso por paso el procedimiento a seguir para generar la estructura de tu centro en Smile and Learn. ¿Cómo vamos a hacerlo? De una manera muy sencilla, recuperando la información de centro que te proporciona Raíces y los correos electrónicos de EducaMadrid.</p>
      <p>Lo primero que debes hacer es conseguir dos ficheros de las plataformas oficiales:</p><p><ul>
      <li>En <b>EducaMadrid</b> debes descargar <b>el fichero completo</b>:</li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Administrador y, en el desplegable, escoge "Panel de Control"</li>
        <li>Pincha en "Gestión de Usuarios"</li>
        <li>En el buscador, selecciona tipo: Alumno. Y pincha en "Buscar"</li>
        <li>Baja hasta el final de las columnas que contienen la información y haz clic a la izquierda, donde se indica "descargar .csv".<span style="color:red;"> Es importante que no lo modifiques en absoluto.</span></li>
        </ol>
      </p>
      <p><ul>
      <li>En la plataforma <b>Raíces</b> tendrás que generar el fichero con el alumnado de tu centro:
      </li></ul></p>
      <p>
        <ol style="margin-left: 30px;">
        <li>Dirígete a Alumnado</li>
        <li>Pincha en Alumnado del centro</li>
        <li>Utiliza los filtros si fuera necesario (para escoger el año académico, etc.)</li>
        <li>En el apartado de exportación de datos, selecciona el formato Excel (.xls)</li>
        <li>Selecciona las siguientes columnas: <b>Alumno/a , Primer apellido, Segundo apellido, Nombre, Fecha de nacimiento, Curso, Unidad, Nº id. Alumno</b></li>
        </ol>
      </p>
      
      <div class="alert alert-warning">
      <div class="alert-heading">Atención</div>
      <div class="alert-body">
      <p>Si no tienes acceso a Raíces, deberás solicitarlo a secretaría o al equipo administrativo y pedirles que, una vez descargado el archivo, no lo modifiquen.</p></div>
      </div>
      
      <p>Una vez tengas estos dos ficheros, estarás listo para generar tu estructura de forma rápida y sencilla. Solo tendrás que seguir los pasos que te explicamos a continuación:</p><p>
      1. Accede a Smile and Learn e inicia sesión con tu correo completo y contraseña de EducaMadrid. 
      </p>
      <p>2. Una vez dentro podrás ver un panel de administración con estadísticas de tu centro educativo. En el menú lateral izquierdo encontrarás la sección “Raíces”.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_2_es.png')}"></p>
      <p>3. Pulsando sobre la sección “Raíces”, aparecerá una ventana de carga de documentos. Arrastra los ficheros que previamente has generado de Raíces y EducaMadrid a su lugar correspondiente (cuadros azules), o haz clic sobre ellos para buscarlos en las carpetas de tu dispositivo. Recuerda que el fichero de Raíces tiene una extensión <b>.xls</b>, mientras que el de EducaMadrid tiene una extensión <b>.csv</b></p>

      <p style="color:red">Es muy importante que los documentos sean los que hayan proporcionado Raíces y EducaMadrid <b>sin alteraciones de ningún tipo</b>.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_3_es.png')}"></p>
      <p>4. Una vez seleccionados los ficheros, haz clic en “Continuar”. Se iniciará el proceso de carga de la información. Esta acción puede durar unos minutos mientras se procesan los datos.</p>
      <p>El sistema será capaz de detectar discrepancias entre la base de datos de Smile and Learn y los documentos oficiales de Raíces y EducaMadrid que has seleccionado, y proporcionará un listado completo de estudiantes indicando el estado del registro y las acciones a realizar sobre el mismo. Una vez finalizada la carga, deberás verificar la información procesada y así confirmar que todo es correcto.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_4_es.png')}"></p>
      <p>5. Si la información que se muestra en la verificación no es correcta, puedes volver al paso anterior y cargar de nuevo los ficheros con la información correcta.</p>
      <p>6. Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón “finalizar”. Este proceso puede durar unos minutos.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/raices/raices_6_es.png')}"></p>
      `,
    },
    25: {
      title: 'Videotutorial Backend',
      lastUpdated: '28 Oct 2022',
      relatedQuestions: [
        {
          id: 20,
          question: '¿Cómo funciona la integración con Raíces?',
          slug: 'how-does-raices-integration-works',
        },
        {
          id: 21,
          question: '¿Qué necesito antes de usar la integración con Raíces?',
          slug: 'what-do-i-need-before-using-raices-integration',
        },
        {
          id: 22,
          question: '¿Qué significan los estados y acciones en la tabla de validación de información?',
          slug: 'what-do-they-mean-the-states-and-actions-in-the-validation-table?',
        },
        {
          id: 24,
          question: 'Integración con Raíces: Manual de Uso',
          slug: 'raices-integration-user-manual',
        },
        {
          id: 25,
          question: 'Videotutorial Backend',
          slug: 'videotutorial',
        }
      ],
      content: `
      <p class="card-text">¡Hola! Este es el videotutorial para aprender a utilizar la plataforma de creación y actualización de estructuras de los colegios asociados al convenio entre Smile and Learn y EducaMadrid.</p>
      <div><div class="embed-responsive embed-responsive-16by9"><iframe src="https://downloads.smileandlearn.com/Tutoriales/Videotutorial+Backend+EducaMadrid.mp4" allowfullscreen="allowfullscreen" class="embed-responsive-item"></iframe></div></div>`,
    },
    30: {
      title: 'Com creo un grup?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Com creo un grup?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Com puc veure i editar un grup?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Com puc esborrar els grups que ja no necessito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Com assigno professors a un grup?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Com afegixo alumnes a un grup?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Com descarrego el meu llistat actual de grups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedeix a la secció de Grups ubicada al menú lateral esquerre. Un cop dins, fes clic al botó verd amb el símbol de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_crear_btn_ca.png')}""></p>
      <p> Es obrirà un formulari on hauràs indicar el nom del grup. Després, fes clic a "Afegir."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/group/grupo_crear_form_ca.png')}""></p>
      <p>El teu grup s'afegirà al final del llistat. Pots editar-lo fent clic al seu nom o seleccionant l'opció "Editar" del menú desplegable de la columna "acció".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_ca.png')}""></p>`,
    },
    31: {
      title: 'Com puc veure i editar un grup?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Com creo un grup?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Com puc veure i editar un grup?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Com puc esborrar els grups que ja no necessito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Com assigno professors a un grup?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Com afegixo alumnes a un grup?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Com descarrego el meu llistat actual de grups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedeix a la secció de Grups situada al menú lateral esquerre. Un cop dins, podràs veure una llista de tots els grups que poses en aquest moment.</p>
      <p>A la columna "Acció", tens l'opció de veure els detalls d'aquell grup, editar-lo o eliminar-lo.</p>
      <p>També pots fer clic al nom del grup per anar a la vista de detalls.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_ca.png')}"></p>
      <h4>Detalls</h4><p>En aquesta vista trobaràs, juntament amb la informació del grup, una llista amb els nens que posseeix actualment el grup. Pots desvincular-los fent clic a la icona vermella amb la cadena ratllada. També pots afegir nous nens fent clic a l'enllaç situat a la cantonada superior de la targeta.</p><p>A més, podràs gestionar els comptes de professors de la mateixa manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_detail_ca.png')}"></p>
      <h4>Edita el Grup</h4>
      <p>Si entres a la vista d'edició, veuràs una primera pestanya on pots canviar l'avatar del grup i el seu nom. No oblidis fer clic a "Desa els Canvis" quan hagis acabat.</p>
      <p>Les dues pestanyes següents et ajudaran a gestionar els teus professors i nens.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_ca.png')}"></p>`,
    },
    32: {
      title: 'Com puc esborrar els grups que ja no necessito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Com creo un grup?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Com puc veure i editar un grup?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Com puc esborrar els grups que ja no necessito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Com assigno professors a un grup?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Com afegixo alumnes a un grup?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Com descarrego el meu llistat actual de grups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedeix a la secció de Grups situada al menú lateral esquerre. Un cop dins, podràs veure un llistat de tots els grups que poses en aquest moment.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_del_ca.png')}"></p>
      <p>A la columna "Acció", fes clic al desplegable i selecciona l'opció "Eliminar".</p>
      <p>També pots marcar les caselles situades al costat de l'avatar del grup i després, al botó vermell amb la paperera, seleccionar l'opció "Eliminar Selecció".</p>
      <p>Tingues en compte que eliminar un grup NO elimina els nens. Aquests seran traslladats a un grup especial anomenat "Nens sense grup".</p>`,
    },
    33: {
      title: 'Com assigno professors a un grup?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Com creo un grup?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Com puc veure i editar un grup?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Com puc esborrar els grups que ja no necessito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Com assigno professors a un grup?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Com afegixo alumnes a un grup?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Com descarrego el meu llistat actual de grups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedeix a la secció de Grups situada al menú lateral esquerre. Un cop dins, podràs veure un llistat de tots els grups que poses en aquest moment.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_ca.png')}""></p>
      <p>A la columna "Acció", fes clic al menú desplegable i selecciona l'opció "Editar".</p>
      <p>També pots clicar al nom del grup per anar a la vista de detalls i després clicar al botó "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_ca.png')}""></p>
      <p>Un cop a la vista d'Edició, selecciona la pestanya de Professors Assignats.</p>
      <p>Selecciona els correus electrònics de tots els professors que necessitis vincular a aquest grup. No oblidis clicar a "Desa les Canvis" quan hagis acabat.</p>`,
    },
    34: {
      title: 'Com afegixo alumnes a un grup?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 30,
          question: 'Com creo un grup?',
          slug: 'how-do-i-create-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 31,
          question: 'Com puc veure i editar un grup?',
          slug: 'how-can-i-see-and-edit-a-group',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 32,
          question: 'Com puc esborrar els grups que ja no necessito?',
          slug: 'how-can-i-delete-the-groups-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 33,
          question: 'Com assigno professors a un grup?',
          slug: 'how-do-i-assign-teachers-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 34,
          question: 'Com afegixo alumnes a un grup?',
          slug: 'how-do-i-assign-students-to-a-group',
          role: ['admin','partner','school-admin','support'],
        },
        {
          id: 35,
          question: 'Com descarrego el meu llistat actual de grups?',
          slug: 'how-do-i-download-a-list-of-my-groups',
          role: ['admin'],
        },
      ],
      content: `<p>Accedeix a la secció de Grups situada al menú lateral esquerre. Un cop dins, podràs veure un llistat de tots els grups que poses en aquest moment.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_list_edit_ca.png')}""></p>
      <p>A la columna "Acció", fes clic al menú desplegable i selecciona l'opció "Editar".</p>
      <p>També pots clicar al nom del grup per anar a la vista de detalls i després clicar al botó "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_edit_teacher_ca.png')}""></p>
      <p>Un cop a la vista d'Edició, selecciona la pestanya de Professors Assignats.</p>
      <p>Selecciona els correus electrònics de tots els professors que necessitis vincular a aquest grup. No oblidis clicar a "Desa les Canvis" quan hagis acabat.</p>`,
    },
    // 35: {
    //   title: 'Com descarrego el meu llistat actual de grups?',
    //   lastUpdated: '10 Sep 2022',
    //   relatedQuestions: [
    //     {
    //       id: 30,
    //       question: 'Com creo un grup?',
    //       slug: 'how-do-i-create-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 31,
    //       question: 'Com puc veure i editar un grup?',
    //       slug: 'how-can-i-see-and-edit-a-group',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 32,
    //       question: 'Com puc esborrar els grups que ja no necessito?',
    //       slug: 'how-can-i-delete-the-groups-i-no-longer-need',
    //       role: ['admin', 'user','partner','school-admin','support'],
    //     },
    //     {
    //       id: 33,
    //       question: 'Com assigno professors a un grup?',
    //       slug: 'how-do-i-assign-teachers-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 34,
    //       question: 'Com afegixo alumnes a un grup?',
    //       slug: 'how-do-i-assign-students-to-a-group',
    //       role: ['admin','partner','school-admin','support'],
    //     },
    //     {
    //       id: 35,
    //       question: 'Com descarrego el meu llistat actual de grups?',
    //       slug: 'how-do-i-download-a-list-of-my-groups',
    //       role: ['admin'],
    //     },
    //   ],
    //   content: `<p>Accedeix a la secció de Grups situada al menú lateral esquerre. Un cop dins, podràs veure un llistat de tots els nens que poses en aquest moment.</p>
    //   <p>Fes clic al botó blau "Exporta tots els registres", amb la icona del document d'Excel, i automàticament es descarregarà el fitxer al teu dispositiu.</p>
    //   <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>`,
    // },
    41: {
      title: 'Com creo un nen?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Com creo un nen?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Com puc veure i editar un nen?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Com puc esborrar els nens que ja no necessito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Com assigno grups a un nen?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Com assigno un correu electrònic a un nen?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Com descarrego el meu llistat actual de nens?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedeix a la secció de Nens situada al menú lateral esquerre. Un cop dins, fes clic al botó verd amb el símbol de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_crear_btn_ca.png')}""></p>
      <p>S'obrirà un formulari on hauràs indicar el nom del nen, la seva data de naixement, llicència d'estudiant (si en té una), grup al qual pertany i número de registre (opcional). A més, tens l'opció d'afegir un correu electrònic familiar per compartir el nen amb un membre de la família (que haurà de tenir una subscripció activa a Smile and Learn).</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-50" src="${require('@/assets/images/help/dreamer/dreamer_crear_form_ca.png')}""></p>
      <p>Després fes clic a "Afegir", i el teu nen s'afegirà al final de la llista. Pots editar-lo fent clic al seu nom o seleccionant l'opció "Editar" al menú desplegable de la columna "Acció".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_ca.png')}""></p>
      `,
    },
    42: {
      title: 'Com puc veure i editar un nen?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Com creo un nen?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Com puc veure i editar un nen?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Com puc esborrar els nens que ja no necessito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Com assigno grups a un nen?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Com assigno un correu electrònic a un nen?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Com descarrego el meu llistat actual de nens?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedeix a la secció de Nens situada al menú lateral esquerre. Un cop dins, fes clic al botó verd amb el símbol de suma.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_ca.png')}""></p>
      <h4>Detalls</h4>
      <p>En aquesta vista, trobaràs, juntament amb la informació del nen, un llistat dels grups que posseeix actualment. Pots desvincular-los fent clic a la icona vermella amb la cadena ratllada. També pots afegir nous grups fent clic al llaç situat a la cantonada superior de la targeta.</p>
      <p>Així mateix, podràs gestionar les llicències d'estudiant de la mateixa manera.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_detail_ca.png')}""></p>
      <h4>Editar Nen</h4>
      <p>Si entres a la vista d'edició, veuràs una primera pestanya on pots canviar l'avatar del nen, el seu nom, data de naixement i número de registre. No oblidis fer clic a "Desa les Canvis" quan hagis acabat.</p>
      <p>Les dues pestanyes següents et ajudaran a gestionar les llicències d'estudiant i els grups.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_ca.png')}"></p>
      `,
    },
    43: {
      title: 'Com puc esborrar els nens que ja no necessito?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Com creo un nen?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Com puc veure i editar un nen?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Com puc esborrar els nens que ja no necessito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Com assigno grups a un nen?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Com assigno un correu electrònic a un nen?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Com descarrego el meu llistat actual de nens?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedeix a la secció de Nens situada al menú lateral esquerre. Un cop a dins, podràs veure un llistat de tots els nens que tens actualment.</p>
      <h4>Desactivar un nen</h4>
      <p>A la columna "Acció", fes clic al desplegable del nen a eliminar i selecciona l'opció "Eliminar". En realitzar aquesta acció, els nens seleccionats es eliminaran i es podran recuperar en un termini de 15 dies. Per fer-ho, cal enviar un correu electrònic a support@smileandlearn.com indicant els seus noms.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_1_ca.png')}""></p>
      <h4>Desactivar nens de manera massiva</h4>
      <p>També pots eliminar diversos nens marcant les caselles al costat de l'avatar de cada nen, i després al botó vermell amb la paperera seleccionar l'opció "Desactivar nens seleccionats".</p>
      <p>En realitzar aquesta acció, els nens seleccionats es eliminaran i es podran recuperar en un termini de 15 dies. Per fer-ho, cal enviar un correu electrònic a support@smileandlearn.com indicant els seus noms.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_2_ca.png')}""></p>
      <h4>Eliminar nens de manera massiva amb un fitxer Excel</h4>
      <p>Una altra opció és donar de baixa nens de manera massiva amb un fitxer Excel. Aquesta opció es pot trobar al botó vermell amb la paperera, seleccionant l'opció "Donar de baixa nens amb Excel".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_3_ca.png')}""></p>
      <p>S'hi mostraran els passos a seguir, una plantilla d'exemple descarregable i el panell de càrrega del document.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_excel_ca.png')}""></p>
      <h4>Eliminar tots els nens</h4>
      <p>Si en pujar l'estructura del teu centre has comès algun error, tens l'opció d'eliminar tots els nens amb un sol botó. Aquest es troba al botó vermell amb la paperera, sota el nom de "Eliminar tots els nens".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_del_4_ca.png')}""></p>
      <hr class="help-divider">
      
      <h3>Quina és la diferència entre "desactivar", "donar de baixa" i "eliminar" un nen?</h3>
      
      <p><b>Desactivar nens seleccionats</b>: L'alumne romandrà vinculat a l'escola, però el docent no el veurà en el seu grup. Es pot reactivar en qualsevol moment.</p>
      <p>Per a això, disposeu d'un termini de 15 dies per enviar un correu electrònic a support@smileandlearn.com indicant els seus noms i el vostre compte d'usuari.</p>
      <p><b>Donar de baixa nens amb Excel</b>: L'alumne es desvincularà completament de l'escola, però podrà seguir utilitzant Smile and Learn amb el seu correu electrònic EducaMadrid i contrasenya. No obstant això, la seva subscripció esdevindrà limitada.</p>
      <p><b>Eliminar tots els registres</b>: Esborren completament tots els nens de l'escola, juntament amb la seva llicència i progrés. No hi ha marxa enrere.</p>
      <hr class="help-divider">
      
      <h3>Què passa si un nen inactiu inicia sessió?</h3>
      <p>Pot accedir a Smile and Learn sense restriccions, però no li apareixerà el perfil vinculat a l'escola. A més, pot crear un nou perfil d'alumne, cosa a evitar si volem reactivar-lo en un futur pròxim.</p>
      <h3>Què passa si un nen desactivat inicia sessió?</h3>
      <p>Pot accedir a Smile and Learn amb restriccions (com si no tingués una llicència de pagament activa), i el seu perfil escolar s'esborrarà completament.</p>
      <h3>Què passa si un nen eliminat inicia sessió?</h3>
      <p>No podrà accedir a Smile and Learn.</p>
      `,
    },
    44: {
      title: 'Com assigno grups a un nen?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Com creo un nen?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Com puc veure i editar un nen?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Com puc esborrar els nens que ja no necessito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Com assigno grups a un nen?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Com assigno un correu electrònic a un nen?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Com descarrego el meu llistat actual de nens?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedeix a la secció de Nens situada al menú lateral esquerre. Un cop a dins, podràs veure un llistat de tots els nens que tens actualment.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_ca.png')}""></p>
      <p>A la columna "Acció", fes clic al desplegable i selecciona l'opció "Editar".</p>
      <p>També pots fer clic al nom del nen per anar a la vista de detalls, i després fer clic al botó "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_groups_ca.png')}""></p>
      <p>Un cop a la vista d'Edició, selecciona la pestanya de Grups.</p>
      <p>Selecciona els noms de tots els grups on vulguis que participi aquest nen. No oblidis fer clic a "Guardar Canvis" quan hagis acabat.</p>
      `,
    },
    45: {
      title: 'Com assigno un correu electrònic a un nen?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Com creo un nen?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Com puc veure i editar un nen?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Com puc esborrar els nens que ja no necessito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Com assigno grups a un nen?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Com assigno un correu electrònic a un nen?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Com descarrego el meu llistat actual de nens?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedeix a la secció de Nens situada al menú lateral esquerre. Un cop a dins, podràs veure un llistat de tots els nens que tens actualment.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_list_edit_ca.png')}""></p>
      <p>A la columna "Acció", fes clic al desplegable i selecciona l'opció "Editar".</p>
      <p>També pots fer clic al nom del nen per anar a la vista de detalls, i després fer clic al botó "Editar".</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/dreamer/dreamer_edit_student_ca.png')}""></p>
      <p>Un cop a la vista d'Edició, selecciona la pestanya de Llicència d'estudiant.</p>
      <p>Selecciona el correu electrònic que correspongui a aquest nen. No oblidis fer clic a "Guardar Canvis" quan hagis acabat.</p>
      `,
    },
    46: {
      title: 'Com descarrego el meu llistat actual de nens?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 41,
          question: 'Com creo un nen?',
          slug: 'how-do-i-create-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 42,
          question: 'Com puc veure i editar un nen?',
          slug: 'how-can-i-see-and-edit-a-dreamer',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 43,
          question: 'Com puc esborrar els nens que ja no necessito?',
          slug: 'how-can-i-delete-the-dreamers-i-no-longer-need',
          role: ['admin', 'user','partner','school-admin','teacher','support'],
        },
        {
          id: 44,
          question: 'Com assigno grups a un nen?',
          slug: 'how-do-i-assign-groups-to-a-dreamer',
          role: ['admin', 'user','partner','school-admin','support'],
        },
        {
          id: 45,
          question: 'Com assigno un correu electrònic a un nen?',
          slug: 'how-do-i-assign-an-email-to-a-dreamer',
          role: ['admin','partner','school-admin','teacher','support'],
        },
        {
          id: 46,
          question: 'Com descarrego el meu llistat actual de nens?',
          slug: 'how-do-i-download-a-list-of-my-dreamers',
          role: ['admin'],
        }
      ],
      content: `<p>Accedeix a la secció de Grups situada al menú lateral esquerre. Un cop a dins, podràs veure un llistat de tots els nens que tens actualment.</p>
      <p>Fes clic al botó blau "Exportar tots els registres", amb l'icona del document Excel, i el fitxer es descarregarà automàticament al teu dispositiu.</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/group/grupo_export_es.png')}""></p>
      `,
    },
    51: {
      title: 'Com elimino l´estructura de la meva escola?',
      lastUpdated: '10 Sep 2022',
      relatedQuestions: [
        {
          id: 51,
          question: 'Com elimino l´estructura de la meva escola?',
          slug: 'how-do-i-delete-school-structure',
          role: ['admin','school-admin'],
        },
      ],
      content: `<p>Al menú lateral esquerra, busca la secció de "Estructura de l'Escola" i fes clic a "Gestionar."</p>
      <p>A continuació, completa el text de confirmació i fes clic en eliminar. Recorda que els nens i el seu progrés no es borraran; només s'esborraran els grups del centre, i els nens seran enviats al grup de "Nens sense grup."</p>
      <p class="ql-align-center"><img class="help-img img-fluid w-100" src="${require('@/assets/images/help/estructura/estructura_ca.png')}""></p>`,
    },
  },
}

export default questionData